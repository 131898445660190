import React from "react";
import ImageCard from "./ImageCard";

export default function OneCardSelect(props: OneCardSelectProps) {
  const { cards, selectedCardId, onCardClick } = props;
  return (
    <div className="flex flex-wrap justify-center">
      {cards.map((card) => (
        <div key={card.id} onClick={() => onCardClick?.(card.id)}>
          <ImageCard {...card} selected={selectedCardId === card.id} />
        </div>
      ))}
    </div>
  );
}

export interface OneCardSelectProps {
  cards: ICards[];
  selectedCardId?: string | null;
  onCardClick?: (cardId: string) => void;
}

export interface ICards {
  id: string;
  imageUrl?: string;
  title?: string;
  description?: string;
}
