import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import { useGetSingleUserQuery } from "../../users/users.api";
// import routes from "./routes";

type Props = {
  element: JSX.Element;
  restrictedTo: string[];
};

const RequireAuth = ({ element, restrictedTo }: Props) => {
  const location = useLocation();
  const id = sessionStorage.getItem("userId") || localStorage.getItem("userId");
  if (id) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: user, isLoading } = useGetSingleUserQuery({ id });
    // console.log("RequireAuth -> user", user);

    if (isLoading) return <Loader />;

    if (user && restrictedTo.includes(user.role)) {
      return element;
    }
  }
  return (
    <Navigate
      to={"/admin/dash/login"}
      state={{ from: location }}
      replace={true}
    />
  );
};

const RequireNoAuth = ({ element }: Props) => {
  const location = useLocation();
  const id = sessionStorage.getItem("userId") || localStorage.getItem("userId");
  if (!id) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // console.log("Require No Auth -> user");

    return element;
  }
  return (
    <Navigate
      to={"/admin/dash/home"}
      state={{ from: location }}
      replace={true}
    />
  );
};

export { RequireAuth, RequireNoAuth };
