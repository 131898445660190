import api from "../../app/api";

const apiUploadImagesTags = api.enhanceEndpoints({
  addTagTypes: ["UploadImages"],
});

export interface IUploadImages {
  urlList: [string];
}

export interface IUploadImage {
  url: string;
}

const uploadImagesApi = apiUploadImagesTags.injectEndpoints({
  endpoints: (builder) => ({
    uploadImages: builder.mutation<IUploadImages, FormData>({
      query: (body) => ({
        url: "users/upload-images",
        method: "POST",
        body,
        prepareHeaders: (headers: any) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
      invalidatesTags: ["UploadImages"],
    }),
    uploadImage: builder.mutation<IUploadImage, FormData>({
      query: (body) => ({
        url: "users/upload-image",
        method: "POST",
        body,
        prepareHeaders: (headers: any) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
      invalidatesTags: ["UploadImages"],
    }),
  }),
});

export const { useUploadImagesMutation, useUploadImageMutation } =
  uploadImagesApi;
