import React from "react";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import "../Calendar.css";

export default function DateTimeCard(props: DateTimeCardProps) {
  const {
    onDateChange,
    selectedDate,
    selectedTime,
    onTimeChange,
    startDate,
    endDate,
    availableDays,
    timeList,
    isLoading = false,
  } = props;
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
  });

  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col lg:flex-row items-start justify-center w-full px-6 space-x-0 space-y-6 lg:space-x-6 lg:rtl:space-x-reverse lg:space-y-0 divide-x ${
        isLoading ? "animate-pulse pointer-events-none" : ""
      }`}
    >
      <div className="flex bg-white shadow-lg rounded-xl overflow-hidden w-auto">
        <Calendar
          className="rounded-lg"
          minDate={
            startDate
              ? startDate
              : new Date(new Date().setDate(new Date().getDate() + 3))
          }
          maxDate={
            endDate
              ? endDate
              : new Date(
                  (startDate ? startDate : new Date()).getFullYear(),
                  (startDate ? startDate : new Date()).getMonth() + 1,
                  (startDate ? startDate : new Date()).getDate()
                )
          }
          tileDisabled={({ date }) => {
            if (availableDays) {
              return !availableDays.includes(date.getDay());
            }
            return false;
          }}
          onChange={onDateChange}
          value={selectedDate}
          calendarType="Arabic"
        />
      </div>
      <div className="flex bg-white shadow-lg rounded-xl overflow-hidden w-full lg:w-7/12">
        <div className="flex flex-col items-center justify-center w-full">
          <div className="text-center pt-2">{t("booking.date_time.time")}</div>
          <div className="text-center pb-2">{selectedDate?.toDateString()}</div>
          <div className="h-[1px] w-full bg-gray-300 my-2"></div>

          {selectedDate ? (
            <div className="flex flex-wrap justify-center content-start box-border overflow-y rounded-md p-4 w-full">
              {timeList?.map((time, index) => (
                <div
                  key={index}
                  className={`block p-[6px] text-center leading-tight box-border cursor-pointer rounded-md ${
                    (selectedTime ? selectedTime[0] === time[0] : false)
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                  style={{
                    width:
                      windowWidth < 1536
                        ? "calc(33% - 10px)"
                        : // : windowWidth < 1536
                          // ? "calc(25% - 10px)"
                          "calc(20% - 10px)",
                    margin: "0.2rem",
                  }}
                  onClick={() => {
                    if (selectedDate) {
                      onTimeChange(time);
                    }
                  }}
                >
                  {time[0]}
                  <br />
                  {time[1]}
                </div>
              ))}
              {/* {Array.from(Array(24).keys()).map((hour) => (
                <div
                  className={`block p-[6px] text-center leading-tight box-border cursor-pointer rounded-md ${
                    selectedTime?.getHours() === hour
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                  style={{
                    width: "calc(20% - 10px)",
                    margin: "0.2rem",
                  }}
                  onClick={() => {
                    if (selectedDate) {
                      const newDate = new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth(),
                        selectedDate.getDate(),
                        hour,
                        0,
                        0
                      );
                      onTimeChange(newDate);
                    }
                  }}
                >
                  {hour < 10
                    ? `${hour % 12 === 0 ? 12 : hour % 12}:00 AM`
                    : `${hour % 12 === 0 ? 12 : hour % 12}:00 ${
                        hour > 12 ? "PM" : "AM"
                      }`}
                  <br />
                  {hour < 10
                    ? `${hour % 12 === 0 ? 12 : hour % 12}:30 AM`
                    : `${hour % 12 === 0 ? 12 : hour % 12}:30 ${
                        hour > 12 ? "PM" : "AM"
                      }`}
                </div>
              ))} */}
            </div>
          ) : (
            <div className=" justify-center items-center flex text-center pt-2 h-[325px]">
              {t("booking.date_time.selectDateFirst")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export interface DateTimeCardProps {
  onDateChange: (date: Date) => void;
  onTimeChange: (time: [string, string]) => void;
  selectedDate: Date | null;
  selectedTime: [string, string] | null;
  startDate?: Date;
  endDate?: Date;
  availableDays?: number[];
  timeList: [string, string][];
  isLoading?: boolean;
}
