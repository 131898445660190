/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux";
import api from "../../app/api";
import { IAvailability, IGetAvailability } from "./availability.types";

const apiWithvailabilityTags = api.enhanceEndpoints({
  addTagTypes: ["availability"],
});

const appointmentDefinitionApi = apiWithvailabilityTags.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableSlots: builder.query<IAvailability, IGetAvailability>({
      query: ({ appointmentId, slotDuration, date, showPastTimeSlots }) => {
        // console.log("showPastTimeSlots", showPastTimeSlots);
        let url = `availabilities/available-slots?appointmentId=${appointmentId}&slotDuration=${slotDuration}`;
        if (date) {
          url += `&date=${date}`;
        }
        if (showPastTimeSlots !== undefined) {
          url += `&showPastTimeSlots=${showPastTimeSlots}`;
        } else {
          url += `&showPastTimeSlots=true`;
        }
        // console.log("url", url);
        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: (result) =>
        result
          ? [{ type: "availability", id: new Date().toISOString() }]
          : ["availability"],
    }),
  }),
});

// Selectors
export const selectvailabilityById = (
  appointmentId: string,
  slotDuration: number,
  date?: string
) =>
  appointmentDefinitionApi.endpoints.getAvailableSlots.select({
    appointmentId,
    slotDuration,
    date,
  });

export const getAvailabilityById = (
  appointmentId: string,
  slotDuration: number,
  date?: string
): IAvailability | undefined => {
  const { data } = useSelector(
    selectvailabilityById(appointmentId, slotDuration, date)
  );
  return data;
};

export const { useGetAvailableSlotsQuery, useLazyGetAvailableSlotsQuery } =
  appointmentDefinitionApi;
export default appointmentDefinitionApi;
