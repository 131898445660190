import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import { containsSpecialChars } from "../utils";

export interface DragNDropUploadFileProps {
  filesToDisplay?: File[];
  titlesToDisplay?: string[];
  handleChange: (files: File | FileList) => void;
  handleFileTitleChange: (title: string, index: number) => void;
  handleRemoveFile: (index: number) => void;
  fileTypes: string[];
}

export default function DragNDropUploadFile(props: DragNDropUploadFileProps) {
  const {
    handleChange,
    fileTypes,
    filesToDisplay,
    titlesToDisplay,
    handleFileTitleChange,
    handleRemoveFile,
  } = props;

  const [error, setError] = React.useState<string | null>(null);
  const { t } = useTranslation();

  const [nameError, setNameError] = React.useState<Record<number, string>>({});

  const [filesPreview, setFilesPreview] = React.useState<string[]>([]);

  React.useEffect(() => {
    // console.log("filesToDisplay Changed", filesToDisplay);
    let files: string[] = [];
    if (!filesToDisplay) setFilesPreview([]);
    else {
      const files = filesToDisplay.map((file) => {
        return URL.createObjectURL(file);
      });
      setFilesPreview(files);
    }
    return () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file);
      });
    };
  }, [filesToDisplay]);

  React.useEffect(() => {
    if (titlesToDisplay) {
      const error: Record<number, string> = {};
      for (let i = 0; i < titlesToDisplay.length; i++) {
        if (titlesToDisplay[i]) {
          console.log("titlesToDisplay[i]", titlesToDisplay[i]);
          if (containsSpecialChars(titlesToDisplay[i])) {
            error[i] = t("errors.nameError", {
              name: t("booking.uploadDocuments.personName"),
            });
          }
        }
      }
      setNameError(error);
    }
  }, [titlesToDisplay]);

  console.log(nameError);

  return (
    <div className="w-full flex flex-col justify-center items-center ">
      <div className="w-full lg:w-10/12 justify-center items-center">
        {filesToDisplay && filesToDisplay.length > 0 && (
          <div className="flex flex-wrap justify-between lg:justify-center w-full">
            {filesToDisplay.map((file, index) => {
              return (
                // if image file, display image else display file name and icon for file type
                <div
                  key={index}
                  className="flex flex-col mx-2 w-44 items-center justify-start"
                >
                  <div
                    key={titlesToDisplay![index]}
                    className="flex flex-col items-center justify-center w-44 h-44 m-2 bg-gray-100 rounded-lg overflow-hidden shadow"
                  >
                    {file.type.startsWith("image") ? (
                      <div className="relative w-full h-full">
                        <button
                          tabIndex={-1}
                          className="w-6 h-6 bg-gray-100 rounded-full shadow-slate-900 shadow-sm absolute top-1 right-1 z-50"
                          onClick={() => {
                            handleRemoveFile(index);
                          }}
                        >
                          <svg
                            className="w-6 h-6 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                        <img
                          className="w-44 h-44 object-cover"
                          src={filesPreview[index]}
                          // src={URL.createObjectURL(file)}
                          // onLoad={(e) => {
                          //   const src = (e.target as any).currentSrc;
                          //   // console.log("src", src);
                          //   URL.revokeObjectURL(src);
                          // }}
                          style={{
                            width: "200px",
                            height: "200px",
                          }}
                          loading="lazy"
                          alt={file.name}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full h-full">
                        <div className="flex items-center justify-center w-12 h-12 mb-2 bg-gray-200 rounded-full">
                          <svg
                            className="w-6 h-6 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </div>
                        <span className="text-sm font-medium text-gray-600 truncate">
                          {file.name}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-full items-start justify-start">
                    <label
                      htmlFor="userInputname1"
                      className="block text-sm font-medium leading-6 text-gray-900 text-start mb-2"
                    >
                      {t("booking.uploadDocuments.personName")} *
                    </label>
                    <div className="relative mb-6">
                      <input
                        type="text"
                        className="peer block min-h-[46px] w-full rounded boder-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                        // id="userInputname1"
                        placeholder="Enter name"
                        value={titlesToDisplay![index]}
                        max={100}
                        maxLength={100}
                        required
                        pattern="^[a-zA-Z\u0621-\u064A\s]+$"
                        onChange={(e) => {
                          // if (containsSpecialChars(e.target.value)) {
                          //   setNameError({
                          //     ...nameError,
                          //     [index]: t("errors.nameError", {
                          //       name: t("booking.uploadDocuments.personName"),
                          //     }),
                          //   });
                          // } else {
                          //   setNameError(
                          //     Object.keys(nameError).reduce(
                          //       (object: any, key: any) => {
                          //         if (key !== index) {
                          //           object[key] = nameError[key];
                          //         }
                          //         return object;
                          //       },
                          //       {}
                          //     )
                          //   );
                          // }

                          handleFileTitleChange(e.target.value, index);
                        }}
                        // {...register("fullName", {
                        //   required: "Full Name is required",
                        //   minLength: {
                        //     value: 3,
                        //     message: "Full Name must be at least 3 characters",
                        //   },
                        // })}
                      />
                      {nameError && nameError[index] && (
                        <p className="text-start text-xs italic text-red-500">
                          {nameError[index]}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="w-full px-12 pt-6">
          <FileUploader
            handleChange={(files: any) => {
              setError(null);
              handleChange(files);
            }}
            name="file"
            types={fileTypes}
            multiple
            required
            // minSize={0.01}
            maxSize={process.env.REACT_APP_UPLOADMAXSIZE}
            dropMessageStyle={{
              // make it reddish
              color: "#ff0000",
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
              left: "-100%",
            }}
            hoverTitle="Drop your files here"
            onTypeError={(err: any) => {
              // console.log("onTypeError", err);
              setError(t("errors.fileTypeError"));
            }}
            onSizeError={(err: any) => {
              console.log("onSizeError", err);
              if (err.includes("too big")) {
                setError(
                  t("errors.fileTooLargeError", {
                    size: process.env.REACT_APP_UPLOADMAXSIZE,
                  })
                );
              } else {
                setError(err);
              }
            }}
          >
            <div className="sc-dmyDGi kFMlGk">
              <div className="flex flex-col items-center justify-center w-full h-64 p-4 border-2 border-dashed rounded-lg">
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z"
                    fill="#0658C2"
                  ></path>
                  <path
                    d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z"
                    fill="#0658C2"
                  ></path>
                  <path
                    d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z"
                    fill="#0658C2"
                  ></path>
                </svg>
                <p className="mt-1 text-sm text-gray-600">
                  <span className="font-medium text-gray-700">
                    {t("booking.uploadDocuments.dragAndDrop")}
                  </span>{" "}
                  {t("booking.uploadDocuments.yourFilesHere")}{" "}
                  <span className="font-medium text-gray-700 underline cursor-pointer">
                    {t("booking.uploadDocuments.browse")}
                  </span>{" "}
                  {t("booking.uploadDocuments.toupload")}
                </p>
                <p className="mt-1 text-xs text-gray-500">
                  {t("booking.uploadDocuments.imageTypes", {
                    size: process.env.REACT_APP_UPLOADMAXSIZE,
                  })}
                </p>
              </div>
            </div>
          </FileUploader>
        </div>
        {error && (
          <div className="pl-2 text-start text-xs italic mt-2 text-red-500 font-medium">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
