/* eslint-disable react-hooks/rules-of-hooks */
import api from "../../app/api";
import { IQueryResults } from "../common/definitions";
import {
  IAppointment,
  IAppointmentPopulated,
  IGetAppointmentRequestParams,
  IPostAppointment,
} from "./appointment.types";

const apiWithAppointmentTags = api.enhanceEndpoints({
  addTagTypes: ["Appointment"],
});

const appointmentApi = apiWithAppointmentTags.injectEndpoints({
  endpoints: (builder) => ({
    createAppointment: builder.mutation<IAppointment, IPostAppointment>({
      query: (body) => ({
        url: "appointments",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Appointment"],
    }),
    getAppointmentByMemberId: builder.query<
      IQueryResults<IAppointmentPopulated>,
      string
    >({
      query: (memberId) => ({
        url: `appointments/membership/${memberId}`,
        method: "GET",
        params: { sortBy: "createdAt:desc" },
      }),
      providesTags: ["Appointment"],
    }),
    checkIfMembershipCanMakeNewAppointment: builder.query<
      boolean,
      {
        memberId: string;
        noOfDays: number;
      }
    >({
      query: (param) => ({
        url: `appointments/membership/within/${param.memberId}`,
        method: "GET",
        params: { noOfDays: param.noOfDays },
      }),
      providesTags: ["Appointment"],
    }),
    getAllAppointment: builder.query<
      IQueryResults<IAppointmentPopulated>,
      IGetAppointmentRequestParams
    >({
      query: ({ appointmentDefinitionId, ...params }) => ({
        url: `appointments/populated/${appointmentDefinitionId}`,
        method: "GET",
        params,
      }),
      providesTags: ["Appointment"],
    }),
    updateAppointmentStatus: builder.mutation<
      boolean,
      { appointmentId: string; status: "pending" | "completed" | "missed" }
    >({
      query: ({ appointmentId, status }) => ({
        url: `appointments/${appointmentId}`,
        method: "PATCH",
        body: { status },
      }),
      invalidatesTags: ["Appointment"],
    }),
  }),
});

export const {
  useCreateAppointmentMutation,
  useGetAppointmentByMemberIdQuery,
  useLazyCheckIfMembershipCanMakeNewAppointmentQuery,
  useGetAllAppointmentQuery,
  useLazyGetAllAppointmentQuery,
  useUpdateAppointmentStatusMutation,
} = appointmentApi;

export default appointmentApi;
