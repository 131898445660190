import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BodyCard from "../components/BodyCard";
import ImageCard from "../components/ImageCard";

export default function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <BodyCard>
      <div className="flex xs:flex-col min-h-full items-center md:flex-row justify-center md:space-x-16 lg:space-x-24 xs:space-y-24 md:space-y-0 rtl:space-x-reverse">
        <div key={"booking-button"} onClick={() => navigate("/booking")}>
          <ImageCard
            title={t("home.booking") ?? ""}
            description={t("home.bookingDescription") ?? ""}
            imageUrl="/deadline.svg"
            objectFit="object-contain"
            width="xs:w-64 min-[400px]:w-80"
            height="xs:h-48 min-[400px]:h-64"
          />
        </div>
        <div key={"inquiry-button"} onClick={() => navigate("/inquiry")}>
          <ImageCard
            title={t("home.inquiry") ?? ""}
            description={t("home.inquiryDescription") ?? ""}
            imageUrl="/appointment.svg"
            objectFit="object-contain"
            width="xs:w-64 min-[400px]:w-80"
            height="xs:h-48 min-[400px]:h-64"
          />
        </div>
      </div>
    </BodyCard>
  );
}
