/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux";
import api from "../../app/api";
import { IReservedSlot, IGetReservedSlot } from "./reserved-slot.types";

const apiWithreservedSlotTags = api.enhanceEndpoints({
  addTagTypes: ["ReservedSlot"],
});

const appointmentDefinitionApi = apiWithreservedSlotTags.injectEndpoints({
  endpoints: (builder) => ({
    getReservedSlot: builder.query<IReservedSlot, IGetReservedSlot>({
      query: ({ appointmentId }) => {
        let url = `reserved-slots/appointment/${appointmentId}`;
        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: (result) =>
        result ? [{ type: "ReservedSlot", id: result.id }] : ["ReservedSlot"],
    }),
  }),
});

// Selectors
export const selectreservedSlotById = (appointmentId: string) =>
  appointmentDefinitionApi.endpoints.getReservedSlot.select({
    appointmentId,
  });

export const getReservedSlotById = (
  appointmentId: string
): IReservedSlot | undefined => {
  const { data } = useSelector(selectreservedSlotById(appointmentId));
  return data;
};

export const { useGetReservedSlotQuery, useLazyGetReservedSlotQuery } =
  appointmentDefinitionApi;
export default appointmentDefinitionApi;
