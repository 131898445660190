import React from "react";
import NotifyContainer from "../modules/common/components/Notify";

import Sidebar from "./SideNav";

export default function AddminWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-row">
      <NotifyContainer />
      <Sidebar />
      <div className="w-full pl-64">{children}</div>
    </div>
  );
}
