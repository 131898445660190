import moment from "moment";
import { IAvailability } from "../modules/appointment-definition/appointment-definition.types";

export function containsSpecialChars(str: string) {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?~]/;
  return specialChars.test(str);
}

export function getAvailabilityBasedOnDay(
  availabilityList: IAvailability[],
  day: Date
): IAvailability | null {
  for (let i = 0; i < availabilityList.length; i++) {
    const startDate = moment(availabilityList[i].availableFrom).tz(
      process.env.REACT_APP_TZ!
    );
    const endDate = moment(availabilityList[i].availableTo).tz(
      process.env.REACT_APP_TZ!
    );

    // check if day is between start and end date
    if (moment(day).isBetween(startDate, endDate, "day", "[]")) {
      return availabilityList[i];
    }
  }
  return null;
}
