import moment from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BodyCard from "../components/BodyCard";
import DateTimeCard from "../components/DateTimeCard";
import DragNDropUploadFile from "../components/DragNDropUploadFile";
import OneCardSelect from "../components/OneCardSelect";
import OneListSelect, { IOption } from "../components/OneListSelect";
import SideStepper from "../components/SideStepper";
import StepperContent from "../components/StepperContent";
import UserInfoForm from "../components/UserInfoForm";
import { ILocation, IService, IUserForm } from "../interfaces";
import { useGetSingleAppointmentDefinitionQuery } from "../modules/appointment-definition/appointment-definition.api";
import {
  useCreateAppointmentMutation,
  useLazyCheckIfMembershipCanMakeNewAppointmentQuery,
} from "../modules/appointment/appointment.api";
import { IPostAppointment } from "../modules/appointment/appointment.types";
import { useLazyGetAvailableSlotsQuery } from "../modules/availability/availability.api";
import { Loader } from "../modules/common/components";
import {
  // IUploadImages,
  useUploadImagesMutation,
} from "../modules/upload-images/upload.api";
import { containsSpecialChars, getAvailabilityBasedOnDay } from "../utils";
import printTemplate from "./print-temp";

function Booking() {
  const { t, i18n } = useTranslation();
  const locations: ILocation[] = [
    {
      id: "1",
      name: t("booking.summary.locationTitle"),
      description: t("booking.summary.locationDescription"),
      address: t("booking.summary.locationDescription"),
      imageUrl: "https://picsum.photos/200/300",
    },
  ];

  const services: IService[] = [
    {
      id: "1",
      name: t("booking.summary.serviceTitle"),
    },
    // {
    //   id: "2",
    //   name: "Service 2",
    // },
  ];
  const formSteps = [
    {
      stepTitle: "Location",
      title: "Select your location",
      description:
        "We need to know where you are so we can show you the right information.",
    },
    {
      stepTitle: "Service",
      title: "Select service",
      description:
        "Please select the service you want to book from the list below.",
    },

    {
      stepTitle: t("booking.userInfo.name"),
      title: t("booking.userInfo.title"),
      description: t("booking.userInfo.description"),
    },
    {
      stepTitle: t("booking.uploadDocuments.name"),
      title: t("booking.uploadDocuments.title"),
      description: t("booking.uploadDocuments.description"),
    },
    {
      stepTitle: t("booking.date_time.name"),
      title: t("booking.date_time.title"),
      description: t("booking.date_time.description"),
    },
    {
      stepTitle: t("booking.summary.name"),
      title: t("booking.summary.title"),
      description: "",
    },
  ];
  const navigate = useNavigate();
  const { data: appointmentDefinition, isLoading } =
    useGetSingleAppointmentDefinitionQuery({
      id: process.env.REACT_APP_APPOINTMENT_ID!,
    });

  const [uploadImages, uploadingResult] = useUploadImagesMutation();
  const [
    checkIfMembershipCanMakeNewAppointment,
    // { isLoading: isCheckingIfMembershipCanMakeNewAppointment },
  ] = useLazyCheckIfMembershipCanMakeNewAppointmentQuery();

  const [createAppointment, createAppointmentResult] =
    useCreateAppointmentMutation();

  const [
    availabilityTrigger,
    // { data: availabilityResult, isLoading: availabilityIsLoading },
    availabilityResult,
    // lastPromiseInfo,
  ] = useLazyGetAvailableSlotsQuery();

  React.useEffect(() => {
    const use = async () => {
      await import("tw-elements" as any);
    };
    use();
  }, []);
  const [index, setIndex] = React.useState(2);
  const [selectedLocation, setSelectedLocation] = React.useState<string | null>(
    "1"
  );

  const [selectedService, setSelectedService] = React.useState<IOption | null>(
    services[0]
  );

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = React.useState<
    [string, string] | null
  >(null);

  const [availableSlots, setAvailableSlots] = React.useState<
    [string, string][]
  >([]);

  const submitRef = React.useRef<HTMLButtonElement>(null);
  const summaryRef = React.useRef<HTMLDivElement>(null);

  const [fileList, setFileList] = React.useState<File[]>([]);
  const [fileTitleList, setFileTitleList] = React.useState<string[]>([]);

  const [uploadedFileList, setUploadedFileList] = React.useState<string[]>([]);

  const [userForm, setUserForm] = React.useState<IUserForm | null>(null);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  // // console.log(uploadedFileList, uploadedFileList.length === 0);

  React.useEffect(() => {
    if (availabilityResult.isLoading) {
      setAvailableSlots([]);
    } else if (availabilityResult.data) {
      setAvailableSlots(availabilityResult.data.availableSlots);
    }
  }, [availabilityResult.data, availabilityResult.isLoading]);

  // // console.log("fileList", fileList);
  const handleChange = (file: File | FileList) => {
    setErrorMessage(null);
    // console.log("file runtime type", typeof file);
    // setFileList([
    //   ...(fileList || []),
    //   ...(file instanceof FileList
    //     ? Array.from(file).map((f) => ({ file: f, title: "" }))
    //     : [{ file, title: "" }]),
    // ]);
    const newFileList = [
      ...(fileList || []),
      ...(file instanceof FileList ? Array.from(file) : [file]),
    ];
    if (
      newFileList.length > parseInt(process.env.REACT_APP_UPLOAD_MAX_COUNT!)
    ) {
      setErrorMessage(
        t("errors.fileMaxError", {
          max: process.env.REACT_APP_UPLOAD_MAX_COUNT,
        })
      );
      return;
    }
    setFileList([
      ...(fileList || []),
      ...(file instanceof FileList ? Array.from(file) : [file]),
    ]);
    setFileTitleList([
      ...(fileTitleList || []),
      ...(file instanceof FileList ? Array.from(file).map((f) => "") : [""]),
    ]);
  };

  const handleRemoveFile = (index: number) => {
    setErrorMessage(null);
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
    const newFileTitleList = [...fileTitleList];
    newFileTitleList.splice(index, 1);
    setFileTitleList(newFileTitleList);
  };

  const handleFileTitleChange = (title: string, index: number) => {
    setErrorMessage(null);
    // // console.log("file runtime type", file);
    const newFileTitleList = [...fileTitleList];
    newFileTitleList[index] = title;
    setFileTitleList(newFileTitleList);
  };

  const next = async () => {
    setErrorMessage(null);
    if (index === 0 && !selectedLocation) {
      setErrorMessage("Please select a location");
      return;
    } else if (index === 1 && !selectedService) {
      setErrorMessage("Please select a service");
      return;
    } else if (index === 2) {
      submitRef.current?.click();
    }
    //   if (index === 3 && fileList.length === 0) {
    //   setErrorMessage(t("errors.personNameError"));
    //   return;
    //   // setIndex(index + 1);
    //   // setErrorMessage(null);
    // } else
    else if (
      index === 3 &&
      fileTitleList.some((f) => !f.trim() || containsSpecialChars(f.trim()))
    ) {
      if (fileTitleList.some((f) => !f.trim()))
        setErrorMessage(t("errors.personNameError"));
      return;
      // setIndex(index + 1);
      // setErrorMessage(null);
    } else if (index === 4 && (!selectedDate || !selectedTime)) {
      setErrorMessage(t("errors.dateTimeError"));
      return;
    } else {
      if (index === formSteps.length - 2) {
        if (!navigator.onLine) {
          setErrorMessage(t("errors.offlineError"));
          return;
        }
        try {
          const canMake = await checkIfMembershipCanMakeNewAppointment({
            memberId: userForm!.membershipId,
            noOfDays: 10,
          });
          if (!canMake.data || canMake.error) {
            setErrorMessage(t("booking.userInfo.canMakeNewError"));
            return;
          }
          // upload images
          const formData = new FormData();
          fileList.forEach((file) => {
            formData.append("images", file);
          });
          let urls: string[];
          // console.log("uploadedFileList", uploadedFileList);
          if (fileList.length > 0) {
            if (!uploadedFileList || uploadedFileList.length === 0) {
              const results = await uploadImages(formData).unwrap();
              // console.log("results", results);
              setUploadedFileList(results.urlList);
              urls = results.urlList;
            } else {
              urls = uploadedFileList;
            }
          } else {
            urls = [];
          }
          // console.log("urls", urls);
          // convert time in format of hh:mm AM/PM to number of minutes from midnight
          const time = selectedTime![0].split(" ");
          const timeParts = time[0].split(":");
          const hours = parseInt(timeParts[0]);
          const minutes = parseInt(timeParts[1]);
          const isAM = time[1].toLocaleLowerCase() === "am";
          const timeInMinutes =
            (hours % 12) * 60 + minutes + (isAM ? 0 : 12 * 60);
          // console.log("timeInMinutes", timeInMinutes);
          // save data to server
          const appointmentObj: IPostAppointment = {
            locationId: appointmentDefinition!.locationList[0].id!,
            serviceId: appointmentDefinition!.serviceList[0].id!,
            status: "pending",
            userInfo: {
              fullName: userForm!.fullName,
              email: userForm!.email,
              nationalId: userForm!.nationalId,
              membershipId: userForm!.membershipId,
              phoneNumber: userForm!.phoneNumber,
              numberOfMembers: userForm!.numberOfMembers,
            },
            documents: fileList.map((file, index) => ({
              fileUrl: urls[index],
              title: fileTitleList[index],
            })),
            date: selectedDate!.toISOString(),
            // date: moment(selectedDate)
            //   .add(-180, "minutes")
            //   .format()
            //   .split("+")[0],
            time: timeInMinutes,
            duration: appointmentDefinition!.availabilityList[0].slotDuration,
            appointmentDefinitionId: appointmentDefinition!.id!,
          };

          // console.log("appointmentObj", appointmentObj);

          await createAppointment(appointmentObj).unwrap();
        } catch (error: any) {
          // check if error has field status, then show error message

          // reset isLoading
          // console.log("error", error);
          if (error.status && error.data.message) {
            console.log(error.data.message, typeof error.data.message);
            if (error.data.message.includes("No files provided")) {
            } else if (error.data.message.includes("too large")) {
              setErrorMessage(
                t("errors.fileTooLargeError", {
                  size: process.env.REACT_APP_UPLOADMAXSIZE,
                })
              );
            } else if (error.data.message.includes("not supported")) {
              setErrorMessage(t("errors.fileTypeError"));
            } else if (
              error.data.message.includes(
                "No appointments found for this member"
              )
            ) {
              setErrorMessage(t("booking.userInfo.canMakeNewError"));
            } else {
              setErrorMessage(error.data.message);
            }
          } else {
            setErrorMessage("Something went wrong, please try again later");
          }

          uploadingResult.reset();
          createAppointmentResult.reset();
          await availabilityTrigger({
            appointmentId: process.env.REACT_APP_APPOINTMENT_ID!,
            slotDuration: 15,
            date: selectedDate?.toISOString(),
            showPastTimeSlots: false,
          });
          return;
        }
      }

      if (index === formSteps.length - 1) {
        navigate("/", { replace: true });
        return;
      }

      setIndex(index + 1);
      setErrorMessage(null);
    }
  };

  const userFormOnSubmit = async (data: any) => {
    const canMake = await checkIfMembershipCanMakeNewAppointment({
      memberId: data.membershipId,
      noOfDays: 10,
    });
    // console.log("data", data);
    if (!canMake.data) {
      setErrorMessage(t("booking.userInfo.canMakeNewError"));
      return;
    }
    setErrorMessage(null);
    // console.log("data", data);
    setUserForm({
      id: "",
      fullName: data.fullName.trim(),
      email: data.email.trim(),
      nationalId: data.nationalId.trim(),
      membershipId: data.membershipId.trim(),
      phoneNumber: data.phoneNumber.trim(),
      numberOfMembers: data.numberOfMembers,
    });
    setIndex(index + 1);
  };

  // // console.log("userForm", userForm);

  const prev = () => {
    if (index === 2) {
      navigate("/");
    } else {
      setErrorMessage(null);
      setIndex(index - 1);
    }
  };

  if (isLoading) return <Loader />;

  let startDate =
    new Date(
      appointmentDefinition?.availabilityList[0].availableFrom!
    ).getTime() <
    new Date(new Date().setDate(new Date().getDate() + 3)).getTime()
      ? new Date(new Date().setDate(new Date().getDate() + 3))
      : new Date(appointmentDefinition?.availabilityList[0].availableFrom!);

  const endDate =
    new Date(
      appointmentDefinition?.availabilityList[
        appointmentDefinition?.availabilityList.length - 1
      ].availableTo!
    ).getTime() < new Date().getTime()
      ? new Date()
      : new Date(
          appointmentDefinition?.availabilityList[
            appointmentDefinition?.availabilityList.length - 1
          ].availableTo!
        );

  console.log(`calendar shittttttt ${moment(selectedDate)}`);

  return (
    <BodyCard>
      <div className="Booking">
        <div className="flex flex-col lg:flex-row">
          <SideStepper
            index={index}
            steps={formSteps.map((step) => step.stepTitle)}
            onStepClick={(index) => console.log(index)}
            dense={false}
          />
          <div className="lg:ltr:pl-4 lg:rtl:pr-4 lg:flex-row w-full">
            <StepperContent
              title={formSteps[index].title}
              description={formSteps[index].description}
              index={index}
              onNextClick={next}
              onBackClick={prev}
              isLastStep={index === formSteps.length - 1}
              isBusy={
                isLoading ||
                createAppointmentResult.isLoading ||
                uploadingResult.isLoading ||
                createAppointmentResult.isLoading
              }
              // isBusy={true}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {index === 0 && (
                    <OneCardSelect
                      cards={locations}
                      selectedCardId={selectedLocation}
                      onCardClick={(id) => {
                        setErrorMessage(null);
                        return setSelectedLocation(id);
                      }}
                    />
                  )}
                  {index === 1 && (
                    <OneListSelect
                      options={services}
                      selectedId={selectedService?.id}
                      onOptionClick={(option) => {
                        setErrorMessage(null);
                        return setSelectedService(option);
                      }}
                    />
                  )}
                  {index === 4 && (
                    <>
                      <div className="flex flex-col mx-8 lg:mx-16 mb-8">
                        <span className="text-sm font-medium text-start text-red-600">
                          {t("booking.date_time.disclaimer")}
                        </span>
                      </div>
                      <DateTimeCard
                        isLoading={
                          isLoading ||
                          createAppointmentResult.isLoading ||
                          uploadingResult.isLoading ||
                          createAppointmentResult.isLoading
                        }
                        onDateChange={async (date) => {
                          const momentDate = moment(date)
                            .hour(0)
                            .minute(0)
                            .second(0)
                            .millisecond(0);
                          setErrorMessage(null);
                          setSelectedDate(momentDate.toDate());
                          setSelectedTime(null);
                          setAvailableSlots([]);
                          console.log(
                            process.env.REACT_APP_TZ,
                            date,
                            momentDate
                              // .add(serverTime.offset, "minutes")
                              .format()
                          );
                          // console.log("date changed", date);
                          await availabilityTrigger({
                            appointmentId:
                              process.env.REACT_APP_APPOINTMENT_ID!,
                            slotDuration: 15,
                            date: momentDate.toISOString(),
                            showPastTimeSlots: false,
                          });
                        }}
                        onTimeChange={(time) => {
                          setErrorMessage(null);
                          setSelectedTime(time);
                          // console.log(time);
                        }}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        startDate={startDate}
                        endDate={endDate}
                        availableDays={
                          // appointmentDefinition?.availabilityList[0]
                          appointmentDefinition
                            ? getAvailabilityBasedOnDay(
                                appointmentDefinition!.availabilityList,
                                selectedDate ?? new Date()
                              )?.availableDays
                            : []
                        }
                        // timeList={[
                        //   ["08:00 AM", "08:15 AM"],
                        //   ["08:15 AM", "08:30 AM"],
                        //   ["08:30 AM", "08:45 AM"],
                        //   ["08:45 AM", "09:00 AM"],
                        //   ["09:00 AM", "09:15 AM"],
                        //   ["09:15 AM", "09:30 AM"],
                        //   ["09:30 AM", "09:45 AM"],
                        //   ["09:45 AM", "10:00 AM"],
                        //   ["10:00 AM", "10:15 AM"],
                        //   ["10:15 AM", "10:30 AM"],
                        //   ["10:30 AM", "10:45 AM"],
                        //   ["10:45 AM", "11:00 AM"],
                        //   ["11:00 AM", "11:15 AM"],
                        //   ["11:15 AM", "11:30 AM"],
                        //   ["11:30 AM", "11:45 AM"],
                        //   ["11:45 AM", "12:00 PM"],
                        //   ["12:00 PM", "12:15 PM"],
                        //   ["12:15 PM", "12:30 PM"],
                        // ]}
                        timeList={availableSlots}
                      />
                    </>
                  )}
                  {index === 2 && (
                    <UserInfoForm
                      submitRef={submitRef}
                      onSubmit={userFormOnSubmit}
                      defaultValues={userForm}
                    />
                  )}

                  {index === 3 && (
                    <DragNDropUploadFile
                      filesToDisplay={fileList}
                      titlesToDisplay={fileTitleList}
                      handleChange={handleChange}
                      handleRemoveFile={handleRemoveFile}
                      handleFileTitleChange={handleFileTitleChange}
                      fileTypes={["jpeg", "png", "jpg", "gif", "svg+xml"]}
                    />
                  )}
                  {index === 5 && (
                    <div className="flex flex-col space-y-4">
                      {/* <ReactToPrint
                        content={() => summaryRef.current}
                        trigger={() => (
                          <div className="flex flex-row justify-end mx-8 lg:mx-16">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                              {t("booking.summary.print")}
                            </button>
                          </div>
                        )}
                      /> */}
                      <div className="flex flex-row justify-end mx-8 lg:mx-16">
                        <button
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                          onClick={() => {
                            var perf = printTemplate;
                            perf = perf
                              .replaceAll(
                                "{{membershipId}}",
                                userForm!.membershipId
                              )
                              .replace("{{fullName}}", userForm!.fullName)
                              .replace("{{email}}", userForm!.email)
                              .replace("{{phoneNumber}}", userForm!.phoneNumber)
                              .replace("{{nationalId}}", userForm!.nationalId)
                              .replace(
                                "{{numberOfMembers}}",
                                userForm!.numberOfMembers.toString()
                              )
                              .replace(
                                "{{location}}",
                                locations.find(
                                  (location) => location.id === selectedLocation
                                )!.name
                              )
                              .replace("{{service}}", selectedService!.name)
                              .replace(
                                "{{date}}",
                                moment(selectedDate)
                                  .tz(process.env.REACT_APP_TZ!)
                                  .format("MMM DD, YYYY")
                              )
                              .replace(
                                "{{time}}",
                                selectedTime![0] + " - " + selectedTime![1]
                              );
                            var w = window.open()!;

                            w.document.write(perf);
                            setTimeout(function () {
                              w.window.print();
                              w.document.close();
                            }, 500);
                          }}
                        >
                          {t("booking.summary.print")}
                        </button>
                      </div>
                      <div className="flex flex-row items-center justify-center h-12 bg-green-100 rounded-md mx-8 lg:mx-16">
                        <span className="text-sm font-medium text-green-600">
                          {t("booking.summary.msg")}
                        </span>
                      </div>
                      <div ref={summaryRef}>
                        <div dir="rtl" className="flex">
                          <div className="flex flex-col mx-8 lg:mx-16">
                            <span className="text-lg font-medium text-start text-red-600 underline">
                              تنويه
                            </span>
                            <span className="text-sm font-medium text-start text-red-600">
                              يرجى ملاحظة أنه سيتم تسليم الكارنيهات للأشخاص
                              المذكورين فقط، وهم العضو العامل، الزوج أو الزوجة،
                              أو وكيل مفوض بتوكيل رسمي عام.
                            </span>

                            <span className="text-sm font-medium text-start text-red-600">
                              لن يتم تسليم الكارنيهات لأي شخص آخر. نرجو منك
                              التأكد من إحضار الوثائق اللازمة لإثبات الهوية
                              لتسهيل عملية التسليم. شكرًا لتفهمكم.
                            </span>
                          </div>
                        </div>
                        <div
                          dir={i18n.dir() === "ltr" ? "ltr" : "rtl"}
                          className="flex flex-col space-y-4 px-8 lg:px-16 py-8"
                        >
                          <div className="flex flex-row  rtl:space-x-reverse">
                            <div className="flex flex-col lg:w-3/12 justify-start items-start">
                              <span className="text-sm font-medium text-gray-500">
                                {t("inquiryResult.location")}
                              </span>
                              <span className="text-sm text-start font-medium text-gray-900">
                                {
                                  locations.find(
                                    (location) =>
                                      location.id === selectedLocation
                                  )?.name
                                }
                              </span>
                              <span className="text-sm text-start font-medium text-gray-900">
                                {
                                  locations.find(
                                    (location) =>
                                      location.id === selectedLocation
                                  )?.description
                                }
                              </span>
                            </div>
                            <div className="flex flex-col lg:w-3/12 justify-start items-start">
                              <span className="text-sm text-start font-medium text-gray-500">
                                {t("inquiryResult.service")}
                              </span>
                              <span className="text-sm font-medium text-gray-900 text-start">
                                {selectedService?.name}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-row rtl:space-x-reverse">
                            <div className="flex flex-col lg:w-3/12 justify-start items-start">
                              <span className="text-sm text-start font-medium text-gray-500">
                                {t("inquiryResult.date")}
                              </span>
                              <span className="text-sm text-start font-medium text-gray-900">
                                {/* {selectedDate?.toDateString()} */}
                                {selectedDate &&
                                  moment(selectedDate)
                                    .tz(process.env.REACT_APP_TZ!)
                                    .format("MMM DD, YYYY")}
                              </span>
                            </div>
                            <div className="flex flex-col lg:w-3/12 justify-start items-start">
                              <span className="text-sm text-start font-medium text-gray-500">
                                {t("inquiryResult.time")}
                              </span>
                              <span className="text-sm text-start font-medium text-gray-900">
                                {selectedTime &&
                                  selectedTime[0] + " - " + selectedTime[1]}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col text-start">
                            <span className="text-lg font-medium text-gray-500 mb-2">
                              {t("booking.userInfo.name")}
                            </span>

                            <div className="text-sm font-medium text-gray-900  space-y-1">
                              <div className="flex flex-row justify-start items-start">
                                <div className="text-sm font-medium text-gray-500 w-6/12 lg:w-3/12">
                                  {t("booking.userInfo.fullName").replace(
                                    "*",
                                    ""
                                  )}
                                </div>
                                <div
                                  className="text-sm font-medium text-gray-900 w-6/12 block break-words xl:w-5/12  max-w-sm"
                                  // style={{ maxWidth: "1000px" }}
                                >
                                  {userForm?.fullName}
                                </div>
                              </div>
                              <div className="flex flex-row justify-start items-start">
                                <div className="text-sm font-medium text-gray-500 w-6/12 lg:w-3/12">
                                  {t("booking.userInfo.emailAddress").replace(
                                    "*",
                                    ""
                                  )}
                                </div>
                                <div
                                  className={`text-sm font-medium text-gray-900  text-ellipsis overflow-hidden lg:w-7/12 flex ${
                                    i18n.dir() === "ltr"
                                      ? "justify-start"
                                      : "justify-end"
                                  }`}
                                  style={{ direction: "ltr" }}
                                >
                                  {userForm?.email}
                                </div>
                              </div>
                              <div className="flex flex-row justify-start items-start">
                                <div className="text-sm font-medium text-gray-500 w-6/12 lg:w-3/12">
                                  {t("booking.userInfo.phoneNumber").replace(
                                    "*",
                                    ""
                                  )}
                                </div>
                                <div
                                  className={`text-sm font-medium text-gray-900 lg:w-3/12 flex ${
                                    i18n.dir() === "ltr"
                                      ? "justify-start"
                                      : "justify-end"
                                  }`}
                                  style={{ direction: "ltr" }}
                                >
                                  {userForm?.phoneNumber}
                                </div>
                              </div>
                              <div className="flex flex-row justify-start items-start">
                                <div className="text-sm font-medium text-gray-500 w-6/12 lg:w-3/12">
                                  {t("booking.userInfo.nationalId").replace(
                                    "*",
                                    ""
                                  )}
                                </div>
                                <div className="text-sm font-medium text-gray-900  text-ellipsis overflow-hidden lg:w-7/12">
                                  {userForm?.nationalId}
                                </div>
                              </div>
                              <div className="flex flex-row justify-start items-start">
                                <div className="text-sm font-medium text-gray-500 w-6/12 lg:w-3/12">
                                  {t("booking.userInfo.membershipId").replace(
                                    "*",
                                    ""
                                  )}
                                </div>
                                <div className="text-sm font-medium text-gray-900  text-ellipsis overflow-hidden lg:w-7/12">
                                  {userForm?.membershipId}
                                </div>
                              </div>
                              <div className="flex flex-row justify-start items-start">
                                <div className="text-sm font-medium text-gray-500 w-6/12 lg:w-3/12">
                                  {t("booking.userInfo.noOfMembers").replace(
                                    "*",
                                    ""
                                  )}
                                </div>
                                <div className="text-sm font-medium text-gray-900  text-ellipsis overflow-hidden lg:w-7/12">
                                  {userForm?.numberOfMembers}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* error message */}
              {errorMessage && (
                <div className="flex flex-row items-center justify-center h-12 m-8 mb-0 bg-red-100 rounded-md">
                  <span className="text-sm font-medium text-red-600">
                    {errorMessage}
                  </span>
                </div>
              )}
            </StepperContent>
          </div>
        </div>
      </div>
    </BodyCard>
  );
}

export default Booking;
