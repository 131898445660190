import React from "react";
import "./App.css";
import "tw-elements";
// import Navbar from "./components/Navbar";
// import BodyCard from "./components/BodyCard";
import RoutesTree from "./components/RoutesTree";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

function App() {
  // check if selected language is ar-EG, then add rtl class to body

  return (
    <div className="App">
      <Provider store={store}>
        <RoutesTree />
      </Provider>
    </div>
  );
}

export default App;
