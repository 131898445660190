import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BodyCard from "../components/BodyCard";
import { useGetAppointmentByMemberIdQuery } from "../modules/appointment/appointment.api";
import { Loader } from "../modules/common/components";
import { useLazyGetReservedSlotQuery } from "../modules/reserved-slot/reserved-slot.api";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

export default function InquiryResult() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { data, isLoading, isFetching } = useGetAppointmentByMemberIdQuery(
    searchParams.get("memberId") || ""
  );
  const [slotTrigger, { data: slotResult, isLoading: slotIsLoading }] =
    useLazyGetReservedSlotQuery();
  // const { location, selectedService, selectedDate, selectedTime, userForm } =
  //   props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (data && data?.results.length > 0) {
      slotTrigger({
        appointmentId: data!.results[0].id,
      });
    }
  }, [data, slotTrigger]);

  if (isLoading || isFetching || slotIsLoading) {
    return <Loader />;
  }

  if (!data || data?.results.length === 0) {
    return (
      <div className="flex flex-col bg-gray-100 bg-opacity-80 rounded-lg  text-center items-center absolute top-1/2	left-1/2 mt-[-100px] ml-[-250px] w-[500px] h-[200px]">
        <div className="w-full font-semibold text-xl p-8 mt-auto">
          <p>{t("inquiryResult.noAppointment")}</p>
          <button
            onClick={() => navigate("/booking")}
            className=" inline-flex items-center justify-center bg-blue-500 text-white px-4 py-2 rounded-lg mt-4 h-[44px]"
          >
            {t("inquiryResult.bookNow") ?? ""}
          </button>
        </div>
      </div>
    );
  }
  const appointment = data!.results[0];

  return (
    <BodyCard opacity={"bg-opacity-85"}>
      <div className="h-full w-full flex justify-center items-center">
        <div className="flex flex-col space-y-8 px-16 w-full xl:w-1/2 bg-gray-100 rounded-lg p-8">
          <div className="flex flex-col md:flex-row   items-start justify-start md:justify-between ">
            <span className="text-lg font-medium text-gray-500">
              {t("inquiryResult.location")}
            </span>
            <div className="flex flex-col md:w-5/12 xl:w-4/12 justify-start items-start text-start">
              <span className="text-sm font-medium text-gray-900">
                {/* {appointment!.locationId?.name} */}
                {t("booking.summary.locationTitle")}
              </span>
              <span className="text-sm font-medium text-gray-900">
                {/* {appointment!.locationId?.description} */}
                {t("booking.summary.locationDescription")}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row   items-start justify-start md:justify-between ">
            <span className="text-lg font-medium text-gray-500">
              {t("inquiryResult.service")}
            </span>
            <div className="flex flex-col md:w-5/12 xl:w-4/12 justify-start items-start text-start">
              <span className="text-sm font-medium text-gray-900">
                {/* {appointment!.serviceId?.name} */}
                {t("booking.summary.serviceTitle")}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row   items-start justify-start md:justify-between ">
            <span className="text-lg font-medium text-gray-500">
              {t("inquiryResult.date")}
            </span>
            <div className="flex flex-col md:w-5/12 xl:w-4/12 justify-start items-start text-start">
              <span className="text-sm font-medium text-gray-900">
                {slotResult &&
                  moment(slotResult?.date)
                    .tz(process.env.REACT_APP_TZ!)
                    .format("MMM DD, YYYY")}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row   items-start justify-start md:justify-between ">
            <span className="text-lg font-medium text-gray-500">
              {t("inquiryResult.time")}
            </span>
            <div className="flex flex-col md:w-5/12 xl:w-4/12 justify-start items-start text-start">
              <span className="text-sm font-medium text-gray-900">
                {/* {selectedTime && selectedTime[0] + " - " + selectedTime[1]} */}
                {slotResult && slotResult.fromTime + " - " + slotResult.toTime}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row   items-start justify-start md:justify-between ">
            <span className="text-lg font-medium text-gray-500">
              {t("inquiryResult.membershipId")}
            </span>
            <div className="flex flex-col md:w-5/12 xl:w-4/12 justify-start items-start text-start">
              <span className="text-sm font-medium text-gray-900">
                {appointment!.userInfo?.membershipId}
              </span>
            </div>
          </div>
          {/* <div className="flex flex-col text-start">
            <span className="text-lg font-medium text-gray-500 mb-2">
              User Info
            </span>

            <div className="text-sm font-medium text-gray-900">
              <div className="flex flex-row justify-start items-start">
                <div className="text-sm font-medium text-gray-500  md:w-5/12 xl:w-4/12">
                  Full Name
                </div>
                <div className="text-sm font-medium text-gray-900  md:w-5/12 xl:w-4/12">
                  {appointment!.userInfo?.fullName}
                </div>
              </div>
              <div className="flex flex-row justify-start items-start">
                <div className="text-sm font-medium text-gray-500 md:w-5/12 xl:w-4/12">
                  Email
                </div>
                <div className="text-sm font-medium text-gray-900 md:w-5/12 xl:w-4/12">
                  {appointment!.userInfo?.email}
                </div>
              </div>
              <div className="flex flex-row justify-start items-start">
                <div className="text-sm font-medium text-gray-500 md:w-5/12 xl:w-4/12">
                  Phone Number
                </div>
                <div className="text-sm font-medium text-gray-900 md:w-5/12 xl:w-4/12">
                  {appointment!.userInfo?.phoneNumber}
                </div>
              </div>
              <div className="flex flex-row justify-start items-start">
                <div className="text-sm font-medium text-gray-500 md:w-5/12 xl:w-4/12">
                  National ID
                </div>
                <div className="text-sm font-medium text-gray-900 md:w-5/12 xl:w-4/12">
                  {appointment!.userInfo?.nationalId}
                </div>
              </div>
              <div className="flex flex-row justify-start items-start">
                <div className="text-sm font-medium text-gray-500 md:w-5/12 xl:w-4/12">
                  Membership ID
                </div>
                <div className="text-sm font-medium text-gray-900 md:w-5/12 xl:w-4/12">
                  {appointment!.userInfo?.membershipId}
                </div>
              </div>
              <div className="flex flex-row justify-start items-start">
                <div className="text-sm font-medium text-gray-500 md:w-5/12 xl:w-4/12">
                  Number of Members
                </div>
                <div className="text-sm font-medium text-gray-900 md:w-5/12 xl:w-4/12">
                  {appointment!.userInfo?.numberOfMembers}
                </div>
              </div>
            </div>
          </div> */}

          <div className="flex flex-row justify-between">
            <button
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center disabled:opacity-50"
              onClick={() => {
                navigate(-1);
              }}
            >
              <svg
                className="w-4 h-4 me-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={i18n.dir() === "ltr" ? "M15 19l-7-7 7-7" : "M9 5l7 7-7 7"}
                />
              </svg>
              <span>{t("booking.back")}</span>
            </button>
          </div>
        </div>
      </div>
    </BodyCard>
  );
}
