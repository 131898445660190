import { ILocation, IService, IUserForm } from "../interfaces";

export default function AppointmentView(props: AppointmentViewProps) {
  const { location, service, date, time, userForm, documents } = props;
  // console.log(location, service);
  return (
    <div className="w-full flex flex-col space-y-4 px-8">
      <div className="flex flex-row ">
        {location && (
          <div className="flex flex-col w-3/12 justify-start items-start">
            <span className="text-sm font-medium text-gray-500">Location</span>
            <span className="text-sm font-medium text-gray-900">
              {location?.name}
            </span>
            <span className="text-sm font-medium text-gray-900 truncate">
              {location?.description}
            </span>
          </div>
        )}
        {service && (
          <div className="flex flex-col w-3/12 justify-start items-start">
            <span className="text-sm font-medium text-gray-500">Service</span>
            <span className="text-sm font-medium text-gray-900 text-start truncate">
              {service?.name}
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col w-3/12 justify-start items-start">
          <span className="text-sm font-medium text-gray-500">Date</span>
          <span className="text-sm font-medium text-gray-900 truncate">
            {date?.toDateString()}
          </span>
        </div>
        <div className="flex flex-col w-3/12 justify-start items-start">
          <span className="text-sm font-medium text-gray-500">Time</span>
          <span className="text-sm font-medium text-gray-900 truncate">
            {time && time[0] + " - " + time[1]}
          </span>
        </div>
      </div>
      <div className="flex flex-col text-start">
        <span className="text-lg font-medium text-gray-500 mb-2">
          User Info
        </span>

        <div className="text-sm font-medium text-gray-900">
          <div className="flex flex-row justify-start items-start">
            <div className="text-sm font-medium text-gray-500  w-3/12">
              Full Name
            </div>
            <div className="text-sm font-medium text-gray-900 text-ellipsis overflow-hidden w-9/12">
              {userForm?.fullName}
            </div>
          </div>
          <div className="flex flex-row justify-start items-start">
            <div className="text-sm font-medium text-gray-500 w-3/12">
              Email
            </div>
            <div className="text-sm font-medium text-gray-900 text-ellipsis overflow-hidden w-9/12">
              {userForm?.email}
            </div>
          </div>
          <div className="flex flex-row justify-start items-start">
            <div className="text-sm font-medium text-gray-500 w-3/12">
              Phone Number
            </div>
            <div className="text-sm font-medium text-gray-900 text-ellipsis overflow-hidden w-9/12">
              {userForm?.phoneNumber}
            </div>
          </div>
          <div className="flex flex-row justify-start items-start">
            <div className="text-sm font-medium text-gray-500 w-3/12">
              National ID
            </div>
            <div className="text-sm font-medium text-gray-900 text-ellipsis overflow-hidden w-9/12">
              {userForm?.nationalId}
            </div>
          </div>
          <div className="flex flex-row justify-start items-start">
            <div className="text-sm font-medium text-gray-500 w-3/12">
              Membership ID
            </div>
            <div className="text-sm font-medium text-gray-900 text-ellipsis overflow-hidden w-9/12">
              {userForm?.membershipId}
            </div>
          </div>
          <div className="flex flex-row justify-start items-start">
            <div className="text-sm font-medium text-gray-500 w-3/12">
              Number of Members
            </div>
            <div className="text-sm font-medium text-gray-900 text-ellipsis overflow-hidden w-9/12">
              {userForm?.numberOfMembers}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col text-start">
        <span className="text-lg font-medium text-gray-500 mb-2">
          Attachments
        </span>
        <div className="flex flex-wrap justify-start">
          {documents &&
            documents.map((doc, index) => {
              return (
                // if image file, display image else display file name and icon for file type
                <div className="flex flex-col">
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center w-32 h-32 m-2 bg-gray-100 rounded-lg shadow"
                  >
                    <img
                      className="w-full h-full object-cover"
                      src={doc.fileUrl}
                      alt={doc.fileUrl}
                    />
                  </div>
                  <span className="text-sm font-medium text-gray-900 overflow-clip text-center truncate w-32">
                    {doc.title}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export interface AppointmentViewProps {
  location?: ILocation;
  service?: IService;
  date?: Date;
  time?: string[];
  userForm?: IUserForm;
  documents?: {
    fileUrl: string;
    title: string;
  }[];
}
