import React from "react";
import {
  CheckIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function SideStepper(props: SideStepperProps) {
  const { i18n } = useTranslation();
  return (
    <div>
      <ol
        className={`hidden lg:block space-y-4 ${props.dense ? "w-60" : "w-72"}`}
      >
        {props.steps.map((step, index) => (
          <li
            key={index}
            onClick={() => props.onStepClick(index)}
            className="cursor-pointer"
          >
            <div
              className={` ${index < 2 ? "hidden" : ""}  w-full px-4 ${
                props.dense ? "py-2" : "py-4"
              } border rounded-lg light:bg-gray-800 ${
                index === props.index
                  ? "text-blue-700 bg-blue-100 border-blue-300  light:border-blue-800 light:text-blue-400"
                  : index < props.index
                  ? "text-green-700  border-green-300  bg-green-50  light:border-green-800 light:text-green-400"
                  : "text-gray-900 bg-gray-100 border-gray-300  light:border-gray-700 light:text-gray-400"
              }`}
              role="alert"
            >
              <div className="flex items-center justify-between">
                <span className="sr-only">{step}</span>
                <h3 className="font-medium">
                  {index + 1 - 2}
                  {". "}
                  {step}
                </h3>
                {index < props.index && <CheckIcon width={20} />}
                {index === props.index &&
                  (i18n.dir() === "ltr" ? (
                    <ArrowRightIcon width={20} />
                  ) : (
                    <ArrowLeftIcon width={20} />
                  ))}
                {/* <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg> */}
              </div>
            </div>
          </li>
        ))}
        {/* <li>
          <div
            className="w-full p-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:border-green-800 dark:text-green-400"
            role="alert"
          >
            <div className="flex items-center justify-between">
              <span className="sr-only">Account info</span>
              <h3 className="font-medium">2. Account info</h3>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </li>
        <li>
          <div
            className="w-full p-4 text-blue-700 bg-blue-100 border border-blue-300 rounded-lg dark:bg-gray-800 dark:border-blue-800 dark:text-blue-400"
            role="alert"
          >
            <div className="flex items-center justify-between">
              <span className="sr-only">Social accounts</span>
              <h3 className="font-medium">3. Social accounts</h3>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </li>
        <li>
          <div
            className="w-full p-4 text-gray-900 bg-gray-100 border border-gray-300 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
            role="alert"
          >
            <div className="flex items-center justify-between">
              <span className="sr-only">Review</span>
              <h3 className="font-medium">4. Review</h3>
            </div>
          </div>
        </li>
        <li>
          <div
            className="w-full p-4 text-gray-900 bg-gray-100 border border-gray-300 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
            role="alert"
          >
            <div className="flex items-center justify-between">
              <span className="sr-only">Confirmation</span>
              <h3 className="font-medium">5. Confirmation</h3>
            </div>
          </div>
        </li> */}
      </ol>
      <ol className="flex lg:hidden items-start w-full p-3 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm sm:text-base mb-2">
        {props.steps.map((step, index) => (
          <li
            key={index}
            className={`${index < 2 ? "hidden" : ""} flex w-full items-center 
            ${
              index === props.index
                ? " text-blue-600"
                : index < props.index
                ? "text-green-600"
                : " text-gray-500"
            }
            ${`
            before:w-full after:w-full 
            ${
              index < props.steps.length - 1
                ? "after:content-[''] after:h-1 after:border-b after:border-4 after:inline-block"
                : ""
            } ${
              index > 2
                ? "before:content-[''] before:h-1 before:border-b before:border-4 before:inline-block"
                : ""
            } ${
              index === props.index
                ? "after:border-blue-300 before:border-blue-300"
                : index < props.index
                ? "after:border-green-300 before:border-green-300"
                : ""
            } `}`}
          >
            <span
              className={`flex items-center justify-center w-10 h-10 ${
                index === props.index
                  ? "bg-blue-300"
                  : index < props.index
                  ? "bg-green-300"
                  : "bg-gray-200"
              } rounded-full lg:h-12 lg:w-12  shrink-0`}
            >
              {index < props.index ? stepIcon["done"] : stepIcon[index]}
            </span>
          </li>
        ))}
      </ol>
    </div>
  );
}

const stepIcon: Record<string, JSX.Element> = {
  0: (
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-blue-600 lg:w-6 lg:h-6 "
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 12a2 2 0 100-4 2 2 0 000 4z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  1: (
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-blue-600 lg:w-6 lg:h-6 "
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 12a2 2 0 100-4 2 2 0 000 4z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  2: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
      />
    </svg>
  ),
  3: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
      />
    </svg>
  ),
  4: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
      />
    </svg>
  ),
  5: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
      />
    </svg>
  ),
  done: (
    <svg
      aria-hidden="true"
      className="w-6 h-6"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
};

export interface SideStepperProps {
  // props for side stepper, selected index, list of steps, on step click
  index: number;
  steps: string[];
  onStepClick: (index: number) => void;
  dense?: boolean;
}

// default values
SideStepper.defaultProps = {
  dense: false,
};
