import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import {
  Filters,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Button, PageButton } from "./shared/Button";
import { SortDownIcon, SortIcon, SortUpIcon } from "./shared/Icons";
import classNames from "./shared/Utils";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 500);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">Search: </span>
      <input
        type="search"
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

// Define a default UI for filtering
function StatusFilter({
  filters,
  setFilters,
}: {
  filters: any;
  setFilters: (value: Filters<any>) => void;
}) {
  const [value, setValue] = React.useState<Filters<any>>(filters);
  const onChange = useAsyncDebounce((value: any) => {
    setFilters(value || undefined);
  }, 500);
  console.log("filters", filters);
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">Status: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value.find((f) => f.id === "status")?.value || ""}
        onChange={(e) => {
          setValue([
            {
              id: "status",
              value: e.target.value || undefined,
            },
          ]);
          onChange([
            {
              id: "status",
              value: e.target.value || undefined,
            },
          ]);
        }}
      >
        <option value="">All</option>
        <option value="pending">Pending</option>
        <option value="completed">Completed</option>
        <option value="missed">Missed</option>
      </select>
    </label>
  );
}

// Define a default UI for filtering
function DateRangeFilter({ filterValue, setFilter }: any) {
  const [startDate, setStartDate] = React.useState<Date | null>(
    filterValue ? filterValue[0] : null
  );
  // default is tomorrow
  const [endDate, setEndDate] = React.useState<Date | null>(
    filterValue ? filterValue[1] : null
  );

  const onSearch = () => {
    setFilter([startDate, endDate]);
  };

  const onClear = () => {
    setStartDate(null);
    setEndDate(null);
    setFilter([null, null]);
  };

  // console.log("filterValue", filterValue, filterValue.length);

  return (
    <div className="flex gap-x-2 items-baseline">
      <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700">from: </span>
        <input
          type="date"
          // disable keyboard input
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={startDate?.toISOString().split("T")[0] || ""}
          onChange={(e) => {
            setStartDate(new Date(e.target.value));
          }}
          placeholder="YYYY-MM-DD"
        />
      </label>
      <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700">to: </span>
        <input
          type="date"
          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={endDate?.toISOString().split("T")[0] || ""}
          onChange={(e) => {
            setEndDate(new Date(e.target.value));
          }}
          placeholder="YYYY-MM-DD"
        />
      </label>
      <Button
        className="disabled:cursor-not-allowed"
        onClick={onSearch}
        disabled={!startDate || !endDate}
      >
        Search
      </Button>

      {filterValue && filterValue[0] && filterValue[1] && (
        <Button className="disabled:cursor-not-allowed" onClick={onClear}>
          clear
        </Button>
      )}
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}: any) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set<any>();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    const sortedOptions = Array.from(options.values());
    return [...sortedOptions];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusPill({ value }: any) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("completed") ? "bg-green-100 text-green-800" : null,
        status.startsWith("pending") ? "bg-yellow-100 text-yellow-800" : null,
        status.startsWith("missed") ? "bg-red-100 text-red-800" : null
      )}
    >
      {status}
    </span>
  );
}

export function AvatarCell({ value, column, row }: any) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img
          className="h-10 w-10 rounded-full"
          src={row.original[column.imgAccessor]}
          alt=""
        />
      </div>
      <div className="ml-4">
        <div className="text-xs font-medium text-gray-900">{value}</div>
        <div className="text-xs text-gray-500">
          {row.original[column.emailAccessor]}
        </div>
      </div>
    </div>
  );
}

function Table({ columns, data, onQueryChanged, handleOnExport }: any) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    pageOptions,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: data.results,
      manualPagination: true,
      manualFilters: true,
      initialState: {
        filters: [
          {
            id: "status",
            value: "all",
          },
        ],
      },
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination, // new
    useFilters
  );

  const canPreviousPage = data.page > 1;
  const canNextPage = data.page < data.totalPages;

  const [query, setQuery] = React.useState<string>("");
  const [status, setStatus] = React.useState<
    "all" | "pending" | "completed" | "missed"
  >("all");

  const [startDate, setStartDate] = React.useState<Date | null>(null);
  // default is tomorrow
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  // // console.log(
  //   "canPreviousPage",
  //   canPreviousPage,
  //   "canNextPage",
  //   canNextPage,
  //   data.page,
  //   data.totalPages
  // );

  // const { pageIndex, pageSize } = state;
  const pageSize = data.limit;
  const pageIndex = data.page - 1;

  console.log("pageIndex", pageIndex);

  const handleOnQueryChanged = (
    page: number,
    limit: number,
    q: string,
    from: Date | null,
    to: Date | null,
    status: "all" | "pending" | "completed" | "missed" = "all"
  ) => {
    setQuery(q);
    setStatus(status);
    if (from && to) {
      setStartDate(from);
      setEndDate(to);
    } else {
      setStartDate(null);
      setEndDate(null);
    }

    const timeOffset = new Date().getTimezoneOffset() * 60000;
    const fromD = from;
    const toD = to;
    // add timeOffset to the date
    const fromStr = fromD ? new Date(fromD.getTime() + timeOffset) : null;
    const toStr = toD ? new Date(toD.getTime() + timeOffset) : null;
    // console.log("fromD", fromD);
    onQueryChanged(
      page,
      limit,
      q,
      {
        from: fromStr?.toISOString(),
        to: toStr?.toISOString(),
      },
      status
    );
  };

  // Render the UI for your table
  return (
    <div className="w-full p-4">
      <div className="flex flex-col gap-y-4">
        <div className="sm:flex sm:gap-x-2 justify-between">
          <div className="flex flex-row sm:flex-row sm:gap-x-2">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={(value: any) => {
                // if (value?.length < 3) return;
                handleOnQueryChanged(
                  1,
                  pageSize,
                  value,
                  startDate,
                  endDate,
                  status
                );
                setGlobalFilter(value);
              }}
            />
            <div className="h-[1px] w-full my-2"></div>
            <StatusFilter
              filters={state.filters}
              setFilters={(value) => {
                const status = value.find((f: any) => f.id === "status")?.value;
                // if (value?.length < 3) return;
                console.log("status", status);
                handleOnQueryChanged(
                  1,
                  pageSize,
                  query,
                  startDate,
                  endDate,
                  status || "all"
                );
                setGlobalFilter(value);
              }}
            />
          </div>
          {/* {headerGroups.map((headerGroup: any) =>
          headerGroup.headers.map((column: any) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )} */}
          <DateRangeFilter
            filterValue={[startDate, endDate]}
            setFilter={(value: any) => {
              // console.log("DateRangeFilter", value);
              handleOnQueryChanged(
                1,
                pageSize,
                query,
                value[0],
                value[1],
                status
              );
            }}
          />
        </div>
        <div className="flex flex-row sm:flex-row sm:gap-x-2">
          <Button
            className="disabled:cursor-not-allowed"
            onClick={() => {
              handleOnExport();
            }}
          >
            Export Filtered Data
          </Button>
        </div>
      </div>
      {/* table */}
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup: any) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="flex items-center justify-between">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row: any, i: number) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="text-xs text-left px-6 py-4 whitespace-nowrap"
                              role="cell"
                            >
                              {cell.column.Cell?.toLocaleString() ===
                              "defaultRenderer" ? (
                                <div className="text-xs text-gray-500">
                                  {cell.render("Cell")}
                                </div>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      <div className="py-3 flex items-center justify-between">
        {/* <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Button>
        </div> */}
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="relative inline-flex items-center space-x-10 rtl:space-x-reverse">
            <div className="relative inline-flex items-center space-x-2 rtl:space-x-reverse">
              <span className="text-sm font-medium text-gray-700">
                Go to page:
              </span>
              <input
                type="number"
                max={data.totalPages}
                min={1}
                className="block w-10 min-w-[60px] rounded-md border-gray-300 pl-2 pe-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                // defaultValue={pageIndex + 1 || 1}
                value={pageIndex + 1 || 1}
                onChange={(e) => {
                  // console.log(e.target.value);
                  const page = e.target.value ? Number(e.target.value) : 1;
                  if (page > data.totalPages || page < 1) {
                    e.target.value = String(pageIndex + 1);
                    return;
                  }
                  // gotoPage(page);
                  handleOnQueryChanged(
                    page,
                    pageSize,
                    query,
                    startDate,
                    endDate,
                    status
                  );
                }}
              />
              <span className="text-sm font-medium text-gray-700">
                of {data.totalPages}
              </span>
            </div>
            <div className="relative inline-flex items-center space-x-2 rtl:space-x-reverse">
              <select
                value={pageSize}
                onChange={(e) => {
                  // setPageSize(Number(e.target.value));
                  handleOnQueryChanged(
                    pageIndex + 1 || 1,
                    Number(e.target.value),
                    query,
                    startDate,
                    endDate,
                    status
                  );
                }}
                className="block min-w-[60px] rounded-md border-gray-300 pl-2 pe-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                {[5, 10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>

              <span className="text-sm font-medium text-gray-700">
                Total items: {data.totalResults}
              </span>
            </div>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px rtl:space-x-reverse"
              aria-label="Pagination"
            >
              <PageButton
                className="rounded-l-md"
                onClick={() => {
                  // gotoPage(0);
                  handleOnQueryChanged(
                    1,
                    pageSize,
                    query,
                    startDate,
                    endDate,
                    status
                  );
                }}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton
                onClick={() => {
                  // previousPage();
                  handleOnQueryChanged(
                    pageIndex,
                    pageSize,
                    query,
                    startDate,
                    endDate,
                    status
                  );
                }}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton
                onClick={() => {
                  // nextPage();
                  handleOnQueryChanged(
                    pageIndex + 2 || 2,
                    pageSize,
                    query,
                    startDate,
                    endDate,
                    status
                  );
                }}
                disabled={!canNextPage}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => {
                  // gotoPage(data.totalPages - 1);
                  handleOnQueryChanged(
                    data.totalPages,
                    pageSize,
                    query,
                    startDate,
                    endDate,
                    status
                  );
                }}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
