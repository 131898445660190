import React from "react";
import { useTranslation } from "react-i18next";

export default function StepperContent(props: StepperContentProps) {
  const { t, i18n } = useTranslation();
  const { title, children, description } = props;
  return (
    <div className="flex flex-col w-full bg-white rounded-lg">
      {/* header with uppercase and divider aligned to start */}
      {title && (
        <div className="flex flex-col justify-start py-6 space-y-6">
          <h1 className="text-2xl sh-heading px-10 text-start">{title}</h1>
          <div className="h-[1px] bg-gray-300"></div>
        </div>
      )}

      {description && (
        <div className="flex flex-col justify-start pt-2 pb-6">
          <p className="text-sm sh-body px-10 text-start">{description}</p>
        </div>
      )}

      {/* content */}
      <div className="flex-grow">{children}</div>

      {/* footer: Back button and next button, back is not shown if index == 0, and next = final if last step */}
      <div className="flex flex-row justify-between px-10 py-6">
        {props.index > 0 && !props.isLastStep ? (
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center disabled:opacity-50"
            onClick={() => props.onBackClick?.()}
            disabled={props.isBusy}
          >
            <svg
              className="w-4 h-4 me-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={i18n.dir() === "ltr" ? "M15 19l-7-7 7-7" : "M9 5l7 7-7 7"}
              />
            </svg>
            <span>{t("booking.back")}</span>
          </button>
        ) : (
          <div></div>
        )}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center disabled:opacity-50"
          onClick={() => props.onNextClick?.()}
          disabled={props.isBusy}
        >
          <span>
            {props.isLastStep ? t("booking.finish") : t("booking.next")}
          </span>
          {props.isBusy && (
            <svg
              className="animate-spin w-4 h-4 ml-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          )}
          {!props.isBusy && !props.isLastStep && (
            <svg
              className="w-4 h-4 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={i18n.dir() === "ltr" ? "M9 5l7 7-7 7" : "M15 19l-7-7 7-7"}
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
}

export interface StepperContentProps {
  title?: string;
  description?: string;
  index: number;
  isLastStep: boolean;
  onBackClick?: () => void;
  onNextClick?: () => void;
  children?: React.ReactNode;
  isBusy?: boolean;
}

// default values
StepperContent.defaultProps = {
  index: 0,
  isLastStep: false,
  isBusy: false,
};
