import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import ar from "react-phone-number-input/locale/ar.json";
import en from "react-phone-number-input/locale/en.json";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";
import { addMethod, number, object, string } from "yup";
import { IUserForm } from "../interfaces";
import { containsSpecialChars } from "../utils";

addMethod(string, "isValidPhoneNumber", function (message) {
  return this.test("isValidPhoneNumber", message, function (value) {
    if (!value) return this.createError({ message });
    const isValid = isValidPhoneNumber(value, "EG");
    // console.log(isValid);
    return isValid;
  });
});

addMethod(string, "isValidName", function (errorMessage) {
  return this.test(`isValidName`, errorMessage, function (value) {
    if (!value) return this.createError({ message: errorMessage });
    const isValid = !containsSpecialChars(value);
    return isValid;
  });
});

declare module "yup" {
  interface StringSchema {
    isValidPhoneNumber(message?: string): StringSchema;
    isValidName(message?: string): StringSchema;
  }
}

export const validationSchema = (t: any) =>
  object().shape({
    fullName: string()
      .required(
        t("errors.requiredError", {
          name: t("booking.userInfo.fullName").replace("*", "").trim(),
        })
      )
      .min(
        3,
        t("errors.minLengthError", {
          name: t("booking.userInfo.fullName").replace("*", "").trim(),
          digit: 3,
        })
        // "Full name must be at least 3 characters"
      )
      .max(
        150,
        t("errors.maxLengthError", {
          name: t("booking.userInfo.fullName").replace("*", "").trim(),
          digit: 150,
        })
      )
      .isValidName(
        t("errors.nameError", {
          name: t("booking.userInfo.fullName").replace("*", "").trim(),
        })
        // "Full name must not contain special characters"
      ),
    email: string()
      .required(
        t("errors.requiredError", {
          name: t("booking.userInfo.emailAddress").replace("*", "").trim(),
        })
        // "Email is required"
      )
      // .email(
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t("errors.emailError")
        // "Invalid email address"
      ),
    nationalId: string()
      .required(
        t("errors.requiredError", {
          name: t("booking.userInfo.nationalId").replace("*", "").trim(),
        })
        // "National Id is required"
      )
      .matches(
        /^[0-9a-zA-Z]+$/,
        t("errors.invalidError", {
          name: t("booking.userInfo.nationalId").replace("*", "").trim(),
        }) +
          " " +
          t("errors.onlyEnglishError", {
            name: t("booking.userInfo.nationalId").replace("*", "").trim(),
          })
      )
      .min(
        5,
        t("errors.minLengthError", {
          name: t("booking.userInfo.nationalId").replace("*", "").trim(),
          digit: 5,
        })
        // "National Id must be at least 8 characters"
      )
      .max(
        14,
        t("errors.maxLengthError", {
          name: t("booking.userInfo.nationalId").replace("*", "").trim(),
          digit: 14,
        })
        // "National Id must be at most 14 characters"
      ),
    membershipId: string()
      .required(
        t("errors.requiredError", {
          name: t("booking.userInfo.membershipId").replace("*", "").trim(),
        })
        // "Membership Id is required"
      )
      .matches(
        /^[0-9]+$/,
        t("errors.invalidError", {
          name: t("booking.userInfo.membershipId").replace("*", "").trim(),
        }) +
          " " +
          t("errors.onlyEnglishNumbersError", {
            name: t("booking.userInfo.membershipId").replace("*", "").trim(),
          })
      )
      .max(
        10,
        t("errors.maxLengthError", {
          name: t("booking.userInfo.membershipId").replace("*", "").trim(),
          digit: 10,
        })
      ),
    phoneNumber: string()
      .required(
        t("errors.requiredError", {
          name: t("booking.userInfo.phoneNumber").replace("*", "").trim(),
        })
        // "Phone Number is required"
      )
      .min(
        8,
        t("errors.minLengthError", {
          name: t("booking.userInfo.phoneNumber").replace("*", "").trim(),
          digit: 8,
        })
        // "Phone Number must be at least 8 characters"
      )
      .max(
        14,
        t("errors.maxLengthError", {
          name: t("booking.userInfo.phoneNumber").replace("*", "").trim(),
          digit: 14,
        })
        // "Phone Number must be at most 14 characters"
      )
      .isValidPhoneNumber(
        t("errors.phoneNumberError")
        // "Invalid phone number"
      ),
    numberOfMembers: number()
      .typeError(
        t("errors.requiredError", {
          name: t("booking.userInfo.noOfMembers").replace("*", "").trim(),
        })
        // "Number of members is required"
      )
      .required(
        t("errors.requiredError", {
          name: t("booking.userInfo.noOfMembers").replace("*", "").trim(),
        })
        // "Number of members is required"
      )
      .min(
        1,
        t("errors.minLengthError", {
          name: t("booking.userInfo.noOfMembers").replace("*", "").trim(),
          digit: 1,
        })
        // "Number of members must be at least 1"
      )
      .max(
        10,
        t("errors.maxLengthError", {
          name: t("booking.userInfo.noOfMembers").replace("*", "").trim(),
          digit: 10,
        })
        // "Number of members must be at most 10"
      ),
  });

export interface UserInfoFormProps {
  onSubmit: (data: IUserForm) => void;
  submitRef: React.RefObject<HTMLButtonElement>;
  defaultValues?: IUserForm | null;
}

const UserInfoForm = (props: UserInfoFormProps) => {
  const { t, i18n } = useTranslation();
  const { onSubmit, defaultValues, submitRef } = props;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUserForm>({
    resolver: yupResolver(validationSchema(t)),
    mode: "all",
    defaultValues: defaultValues ? defaultValues : undefined,
  });

  const onSubmitForm = handleSubmit(
    (data) => onSubmit(data)
    // (err) => console.log(err)
  );

  return (
    <div className="flex flex-col items-stretch lg:items-start justify-start w-auto h-full mx-10 lg:mx-14 pb-8">
      <div className="block w-auto lg:w-1/2  ">
        <form id="myForm" className="w-full" onSubmit={onSubmitForm}>
          <label
            htmlFor="userInputname1"
            className="block text-sm font-medium leading-6 text-gray-900 text-start mb-2"
          >
            {t("booking.userInfo.fullName")}
          </label>
          <div className="relative mb-6">
            <input
              type="text"
              className="peer block min-h-[46px] w-full rounded boder-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
              // id="userInputname1"
              placeholder="Enter name"
              max={150}
              min={3}
              {...register("fullName")}
            />
            {errors.fullName && (
              <p className="text-start text-xs italic text-red-500">
                {errors.fullName?.message?.toString() ?? ""}
              </p>
            )}
          </div>
          <div className="relative mb-12">
            <label
              htmlFor="userInputEmail1"
              className="block text-sm font-medium leading-6 text-gray-900 text-start mb-2"
            >
              {t("booking.userInfo.emailAddress")}
            </label>
            <div style={{ direction: "ltr" }}>
              <input
                type="email"
                className="peer block min-h-[46px] w-full rounded boder-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                // id="userInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
            </div>

            <small
              id="emailHelp"
              className={`${
                errors.email ? "pt-4" : ""
              } text-start absolute w-full text-neutral-500 right-0`}
              data-te-input-helper-ref
            >
              {t("booking.userInfo.emailHelperText")}
            </small>
            {errors.email && (
              <p className="text-start text-xs italic text-red-500">
                {errors.email.message?.toString()}
              </p>
            )}
          </div>
          <div className="relative mb-6">
            <label
              htmlFor="userInputphoneNumber1"
              className="block text-sm font-medium leading-6 text-gray-900 text-start mb-2 "
            >
              {t("booking.userInfo.phoneNumber")}
            </label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                validate: (value) => {
                  // console.log(value);
                  // console.log(isValidPhoneNumber(value));

                  return isValidPhoneNumber(value);
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div style={{ direction: "ltr" }}>
                  <PhoneInput
                    // country="EG"
                    defaultCountry="EG"
                    labels={i18n.language === "ar-EG" ? ar : en}
                    international
                    // countryCallingCodeEditable={false}
                    numberInputProps={{
                      className:
                        "peer block min-h-[46px] w-full rounded boder-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0",
                    }}
                    // id="userInputphoneNumber1"
                    placeholder="Enter phone number"
                    value={value}
                    onChange={(e) => {
                      // console.log("Enter phone number", e);
                      if (e) {
                        onChange(e);
                      }
                    }}
                  />
                </div>
              )}
            />
            {errors.phoneNumber && (
              <p className="text-start text-xs italic text-red-500">
                {errors.phoneNumber.message?.toString() ?? "Invalid phone"}
              </p>
            )}
          </div>
          <div className="relative mb-6">
            <label
              htmlFor="userInputnid1"
              className="block text-sm font-medium leading-6 text-gray-900 text-start mb-2"
            >
              {t("booking.userInfo.nationalId")}
            </label>
            <input
              type="text"
              className="peer block min-h-[46px] w-full rounded boder-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
              // id="userInputnid1"
              placeholder="Enter national ID"
              maxLength={14}
              minLength={5}
              pattern="[0-9a-zA-Z]{5,14}"
              onKeyDown={(e) => {
                // not white space, dont use keyCode as it is deprecated
                if (e.key === " ") {
                  e.preventDefault();
                }
              }}
              {...register("nationalId")}
            />
            {errors.nationalId && (
              <p className="text-start text-xs italic text-red-500">
                {errors.nationalId.message?.toString()}
              </p>
            )}
          </div>
          <div className="relative mb-6">
            <label
              htmlFor="userInputmid1"
              className="block text-sm font-medium leading-6 text-gray-900 text-start mb-2"
            >
              {t("booking.userInfo.membershipId")}
            </label>
            <input
              type="text"
              className="peer block min-h-[46px] w-full rounded boder-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
              // id="userInputmid1"
              max="10"
              pattern="[0-9]*"
              placeholder="Enter membership ID"
              onKeyDown={(e) => {
                // not white space, dont use keyCode as it is deprecated
                if (e.key === " ") {
                  e.preventDefault();
                }
              }}
              {...register("membershipId")}
            />
            {errors.membershipId && (
              <p className="text-start text-xs italic text-red-500">
                {errors.membershipId.message?.toString()}
              </p>
            )}
          </div>
          <div className="relative mb-6">
            <label
              htmlFor="userInputmid1"
              className="block text-sm font-medium leading-6 text-gray-900 text-start mb-2"
            >
              {t("booking.userInfo.noOfMembers")}
            </label>
            <input
              min="1"
              max="10"
              type="number"
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              className="peer block min-h-[46px] w-full rounded boder-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
              // id="userInputnumberOfMembers1"
              placeholder="Enter number of members"
              aria-describedby="numberOfMembersHelp"
              {...register("numberOfMembers", {
                required: "Number of members is required",

                min: {
                  value: 1,
                  message: t("errors.minLengthError", {
                    name: t("booking.userInfo.noOfMembers")
                      .replace("*", "")
                      .trim(),
                    digit: 1,
                  }),
                },
                max: {
                  value: 10,
                  message: t("errors.maxLengthError", {
                    name: t("booking.userInfo.noOfMembers")
                      .replace("*", "")
                      .trim(),
                    digit: 10,
                  }),
                },
              })}
              onInput={(e) => {
                // accept only numbers from 1 to 6
                e.currentTarget.value = Math.max(
                  0,
                  parseInt(e.currentTarget.value)
                ).toString();

                // accept only numbers from 1 to 6
                if (parseInt(e.currentTarget.value) > 10) {
                  e.currentTarget.value = "10";
                }
              }}
            />
            <small
              id="numberOfMembersHelp"
              className={`${
                errors.numberOfMembers ? "pt-4" : ""
              } text-start absolute w-full text-neutral-500 right-0`}
              data-te-input-helper-ref
            >
              {t("booking.userInfo.noOfMembersHelperText")}
            </small>
            {errors.numberOfMembers && (
              <p className="text-start text-xs italic text-red-500">
                {errors.numberOfMembers.message?.toString()}
              </p>
            )}
          </div>
          {/* <button ref={submitRef} type="submit" style={{ display: "none" }} /> */}
          <button ref={submitRef} type="submit" className="hidden" />
        </form>
      </div>
    </div>
  );
};
export default UserInfoForm;
