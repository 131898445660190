import React from "react";

export default function BodyCard(props: BodyCardProps) {
  const { children } = props;
  return (
    <div className="flex justify-center z-50">
      <div
        className={`w-full min-h-[86vh] lg:m-10 p-2 lg:p-6 bg-gray-100 ${
          props.opacity || "bg-opacity-50"
        } border border-gray-200/[.6] rounded-lg shadow`}
      >
        {children}
      </div>
    </div>
  );
}

export interface BodyCardProps {
  children?: React.ReactNode;
  opacity?: string;
}
