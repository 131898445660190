import React from "react";

export default function ImageCard(props: ImageCardProps) {
  const {
    imageUrl,
    title,
    description,
    selected,
    onClick,
    width,
    height,
    objectFit,
  } = props;
  // rectangle card with image, title and description, card is clickable
  return (
    <div
      className={`flex pt-4 flex-col ${width} bg-white rounded-lg shadow cursor-pointer ${
        selected ? "border-2 border-blue-500" : ""
      }`}
      onClick={onClick}
    >
      {/* image */}
      <img
        className={` ${height} ${objectFit}  ${
          selected ? "opacity-100" : " rounded-t-lg opacity-100"
        }`}
        src={imageUrl}
        alt={imageUrl}
      />

      {/* title */}
      <div className="flex flex-col justify-start py-4 space-y-2">
        <h1 className="text-2xl sh-heading px-2 text-center">{title}</h1>
        <p className="text-sm sh-body px-2 text-center">{description}</p>
      </div>
    </div>
  );
}

export interface ImageCardProps {
  imageUrl?: string;
  title?: string;
  description?: string;
  onClick?: () => void;
  selected?: boolean;
  width: string;
  height: string;
  objectFit?:
    | "object-cover"
    | "object-contain"
    | "object-fill"
    | "object-none"
    | "object-scale-down";
}

//default width is 48
ImageCard.defaultProps = {
  width: "w-48",
  height: "h-32",
  objectFit: "object-cover",
};
