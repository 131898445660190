/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux";
import api from "../../app/api";
import {
  IAppointmentDefinitionPopulated,
  IGetAppointmentDefinitionPopulated,
} from "./appointment-definition.types";

const apiWithAppointmentDefinitionTags = api.enhanceEndpoints({
  addTagTypes: ["AppointmentDefinition"],
});

const appointmentDefinitionApi =
  apiWithAppointmentDefinitionTags.injectEndpoints({
    endpoints: (builder) => ({
      getSingleAppointmentDefinition: builder.query<
        IAppointmentDefinitionPopulated,
        IGetAppointmentDefinitionPopulated
      >({
        query: ({ id }) => ({
          url: `appointment-definition/populated/${id}`,
          method: "GET",
        }),
        providesTags: (result) =>
          result
            ? [{ type: "AppointmentDefinition", id: result.id }]
            : ["AppointmentDefinition"],
      }),
    }),
  });

// Selectors
export const selectAppointmentDefinitionById = (
  id: IAppointmentDefinitionPopulated["id"]
) =>
  appointmentDefinitionApi.endpoints.getSingleAppointmentDefinition.select({
    id,
  });

export const getAppointmentDefinitionPopulatedById = (
  id: IAppointmentDefinitionPopulated["id"]
): IAppointmentDefinitionPopulated | undefined => {
  const { data } = useSelector(selectAppointmentDefinitionById(id));
  return data;
};

export const { useGetSingleAppointmentDefinitionQuery } =
  appointmentDefinitionApi;
export default appointmentDefinitionApi;
