import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../404";
import NotifyContainer from "../modules/common/components/Notify";
import { RequireAuth, RequireNoAuth } from "../modules/common/routing";
import restrictions from "../modules/common/routing/restrictions";
import AdminAppointments from "../pages/AdminAppointments";
import AdminHome from "../pages/AdminHome";
import Booking from "../pages/Booking";
import Home from "../pages/Home";
import Inquiry from "../pages/Inquiry";
import InquiryResult from "../pages/InquiryResult";
import Login from "../pages/Login";
import AddminWrapper from "./AddminWrapper";
import AppWrapper from "./AppWrapper";

export default function RoutesTree() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppWrapper>
            <Home />
          </AppWrapper>
        }
      />
      <Route
        path="booking"
        element={
          <AppWrapper>
            <Booking />
          </AppWrapper>
        }
      />
      <Route
        path="inquiry"
        element={
          <AppWrapper>
            <Inquiry />
          </AppWrapper>
        }
      />
      <Route
        path="inquiryResult"
        element={
          <AppWrapper>
            <InquiryResult />
          </AppWrapper>
        }
      />
      <Route
        path="admin/dash/login"
        element={
          <RequireNoAuth
            element={
              <>
                <NotifyContainer />
                <Login />
              </>
            }
            restrictedTo={restrictions.none}
          />
        }
      />
      {/* <Route
        path="admin/dash/signup"
        element={
          <>
            <NotifyContainer />
            <Register />
          </>
        }
      /> */}
      <Route
        path="admin/dash/home"
        element={
          <RequireAuth
            element={
              <AddminWrapper>
                <AdminHome />
              </AddminWrapper>
            }
            restrictedTo={restrictions.none}
          />
        }
      />
      <Route
        path="admin/dash/appointments"
        element={
          <RequireAuth
            element={
              <AddminWrapper>
                <AdminAppointments />
              </AddminWrapper>
            }
            restrictedTo={restrictions.none}
          />
        }
      />

      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}
