import React from "react";
import BodyCard from "./BodyCard";
import Navbar from "./Navbar";
import i18n from "../i18n";

export default function AppWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  document.body.dir = i18n.dir();
  return (
    <div
      className={`min-h-screen w-full bg-rowing-background bg-no-repeat bg-cover`}
    >
      <Navbar />

      {/* <BodyCard></BodyCard> */}
      {children}
    </div>
  );
}
