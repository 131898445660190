import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import BodyCard from "../components/BodyCard";

const validationSchema = (t: any) =>
  object().shape({
    membershipId: string()
      .required(
        t("errors.requiredError", {
          name: t("booking.userInfo.membershipId").replace("*", "").trim(),
        })
        // "Membership Id is required"
      )
      .matches(
        /^[0-9]+$/,
        t("errors.invalidError", {
          name: t("booking.userInfo.membershipId").replace("*", "").trim(),
        }) +
          " " +
          t("errors.onlyEnglishNumbersError", {
            name: t("booking.userInfo.membershipId").replace("*", "").trim(),
          })
      )
      .max(
        10,
        t("errors.maxLengthError", {
          name: t("booking.userInfo.membershipId").replace("*", "").trim(),
          digit: 10,
        })
      ),
  });

export default function Inquiry() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{
    membershipId: string;
  }>({
    resolver: yupResolver(validationSchema(t)),
  });

  return (
    <BodyCard opacity={"bg-opacity-85"}>
      <div className="flex justify-center">
        <div className="flex flex-col sm:items-start w-full xl:w-1/2">
          <div className="flex flex-col sm:flex-row items-center sm:items-start sm:space-x-10 rtl:space-x-reverse w-full">
            <img
              className={`w-40 object-contain inline my-8`}
              src={"/appointment.svg"}
              alt={"appointment.svg"}
            />
            <div className="sm:mt-8 max-sm:w-full mb-20 sm:mb-6">
              <h1 className="text-3xl font-semibold mb-6 sm:text-start">
                {t("inquiry.title") ?? ""}
              </h1>

              <p className="sm:text-start">{t("inquiry.description") ?? ""}</p>
            </div>
          </div>

          <div className="flex justify-center w-full">
            <form
              className="w-full"
              onSubmit={handleSubmit((data) => {
                navigate(`/inquiryResult?memberId=${data.membershipId}`, {
                  state: data,
                });
              })}
            >
              <div className="flex flex-col lg:flex-row items-start lg:items-end lg:space-x-6 rtl:space-x-reverse w-full">
                <div className="flex flex-col items-start lg:flex-auto max-lg:w-full">
                  <label
                    htmlFor="membership-id"
                    className="text-md ml-2 font-medium"
                  >
                    {t("inquiry.membershipId") ?? ""}
                  </label>
                  <input
                    type="text"
                    id="membership-id"
                    className="w-full border-2 border-gray-300 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-blue-500"
                    placeholder={t("inquiry.membershipIdPlaceholder") ?? ""}
                    {...register("membershipId", {
                      required: "Membership ID is required",
                    })}
                  />
                </div>

                <div className="flex-initial max-lg:w-full">
                  <button
                    type="submit"
                    className="w-full inline-flex items-center justify-center bg-blue-500 text-white px-4 py-2 rounded-lg mt-4 h-[44px]"
                  >
                    {t("inquiry.viewAppointment") ?? ""}
                  </button>
                </div>
              </div>
              {errors.membershipId && (
                <p className="text-start text-xs italic text-red-500">
                  {errors.membershipId.message?.toString()}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </BodyCard>
  );
}
