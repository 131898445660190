import React from "react";

export default function OneListSelect(props: OneListSelectProps) {
  return (
    <div className="w-full flex flex-wrap justify-center ">
      <ol className={`space-y-4 w-72`}>
        {props.options.map((option) => (
          <li
            key={option.id}
            onClick={() => props.onOptionClick(option)}
            className="cursor-pointer"
          >
            <div
              className={`w-full px-4 py-4 border rounded-lg light:bg-gray-800 ${
                option.id === props.selectedId
                  ? "text-blue-700 bg-blue-100 border-blue-300  light:border-blue-800 light:text-blue-400"
                  : "text-gray-900 bg-gray-100 border-gray-300  light:border-gray-700 light:text-gray-400"
              }`}
              role="alert"
            >
              <div className="flex items-center justify-between">
                <span className="sr-only">{option.name}</span>
                <h3 className="font-medium">{option.name}</h3>
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}

export interface OneListSelectProps {
  options: IOption[];
  selectedId?: string;
  onOptionClick: (option: IOption) => void;
}

export interface IOption {
  id: string;
  name: string;
}
