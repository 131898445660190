import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

interface Language {
  code: string;
  name: string;
}

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const languages = [
    { code: "en-US", name: "English" },
    { code: "ar-EG", name: "العربية" },
  ];
  const selectedLanguage = languages.find(
    (language) => language.code.split("-")[0] === i18n.language.split("-")[0]
  ) || { code: "en-US", name: "English" };
  // console.log(i18n.language, selectedLanguage);

  const handleLanguageChange = async (language: Language) => {
    await i18n.changeLanguage(language.code);
    document.body.dir = i18n.dir();
    setIsOpen(false);
  };
  const LANGUAGE_SELECTOR_ID = "language-selector";
  useEffect(() => {
    const handleWindowClick = (event: any) => {
      const target = event.target.closest("button");
      if (target && target.id === LANGUAGE_SELECTOR_ID) {
        return;
      }
      setIsOpen(false);
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);
  return (
    <div>
      <nav
        className="relative flex w-full items-center  bg-neutral-100/[.2] py-2 shadow-md shadow-black/5  flex-wrap justify-start top-0 z-auto"
        data-te-navbar-ref
      >
        <div className="flex w-full flex-wrap items-center justify-between px-4 lg:px-6">
          <button
            className=" border-0 bg-transparent py-2 px-2.5 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0  hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-7 w-7"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </button>
          <div
            className=" flex-grow  items-center !flex basis-auto"
            id="navbarSupportedContent1"
            data-te-collapse-item
          >
            <a
              className="me-2 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 mt-0"
              href="/"
            >
              <img
                src="/logoR.png"
                style={{ height: "50px", cursor: "pointer" }}
                alt=""
                loading="lazy"
              />
            </a>

            <ul
              className="p-2 list-style-none me-auto flex flex-row w-[200px] lg:w-full text-stone-800"
              data-te-navbar-nav-ref
            >
              <div className="truncate">{t("navbar.title")}</div>
            </ul>
          </div>

          {/* <!-- Right elements --> */}
          <div className="relative flex items-center">
            {/* <span className="fi fi-us fis"></span> */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 lg:px-4 py-2 bg-white/[.4] text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 space-x-2 rtl:space-x-reverse"
              id={LANGUAGE_SELECTOR_ID}
              aria-expanded={isOpen}
            >
              <FlagIcon countryCode={selectedLanguage!.code.split("-")[1]} />
              <span className="hidden lg:block">{selectedLanguage!.name}</span>
              <svg
                className="-me-1 ms-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {isOpen && (
              <div
                className="ltr:origin-top-right rtl:origin-top-left absolute top-12 ltr:-right-2 rtl:-left-2 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby={LANGUAGE_SELECTOR_ID}
              >
                <div
                  className="relative py-1 grid grid-cols-1 gap-2"
                  role="none"
                >
                  {languages.map((language, index) => {
                    return (
                      <button
                        key={language.code}
                        onClick={() => handleLanguageChange(language)}
                        className={`${
                          selectedLanguage?.code === language.code
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700"
                        } space-x-4 rtl:space-x-reverse px-4 py-2 text-sm text-start items-center inline-flex hover:bg-gray-100 ${
                          index % 2 === 0 ? "rounded-r" : "rounded-l"
                        }`}
                        role="menuitem"
                      >
                        <FlagIcon countryCode={language.code.split("-")[1]} />
                        <span className="truncate">{language.name}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
            {/* <a
              className="me-4 text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30    [&.active]:text-black/90 "
              href="#"
            >
              <span className="[&>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                </svg>
              </span>
            </a>
            <div className="relative" data-te-dropdown-ref>
              <a
                className="hidden-arrow me-4 flex items-center text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30    [&.active]:text-black/90 "
                href="#"
                id="dropdownMenuButton1"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                <span className="[&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span className="absolute -mt-2.5 ml-2 rounded-full bg-red-700 py-0 px-1.5 text-xs text-white">
                  1
                </span>
              </a>
              <ul
                className="absolute left-auto right-0 z-[1000] float-left m-0 mt-1 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg  [&[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownMenuButton1"
                data-te-dropdown-menu-ref
              >
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400  "
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Action
                  </a>
                </li>
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400  "
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Another action
                  </a>
                </li>
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400  "
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
            <div className="relative" data-te-dropdown-ref>
              <a
                className="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
                href="#"
                id="dropdownMenuButton2"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                <img
                  src="https://tecdn.b-cdn.net/img/new/avatars/2.jpg"
                  className="rounded-full"
                  style={{ height: "25px", width: "25px" }}
                  alt=""
                  loading="lazy"
                />
              </a>
              <ul
                className="absolute left-auto right-0 z-[1000] float-left m-0 mt-1 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg  [&[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownMenuButton2"
                data-te-dropdown-menu-ref
              >
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400  "
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Action
                  </a>
                </li>
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400  "
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Another action
                  </a>
                </li>
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400  "
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Something else here
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </nav>
    </div>
  );
}

const FlagIcon = ({ countryCode }: { countryCode: string }) => (
  <span
    className={`fib fi-${countryCode.toLocaleLowerCase()} w-7 h-7 rounded-full`}
    style={{ backgroundSize: "cover" }}
  ></span>
);
