import { PencilSquareIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { Column } from "react-table";
import { Tooltip } from "react-tooltip";
import XLSX from "sheetjs-style";
import AppointmentView from "../components/AppointmentView";
import Table, { StatusPill } from "../components/Table"; // new
import {
  useGetAllAppointmentQuery,
  useLazyGetAllAppointmentQuery,
  useUpdateAppointmentStatusMutation,
} from "../modules/appointment/appointment.api";
import { IAppointmentPopulated } from "../modules/appointment/appointment.types";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        alert("You clicked outside of me!");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function useComponentVisible(
  initialIsVisible: boolean,
  onVisibilityCallback: (isVisible: boolean) => void
) {
  const [isComponentVisible, setIsComponentVisible] =
    React.useState(initialIsVisible);
  const ref = React.useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
      onVisibilityCallback(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

const getData = () => {
  const data = [
    {
      name: "Jane Cooper",
      email: "jane.cooper@example.com",
      title: "Regional Paradigm Technician",
      department: "Optimization",
      status: "Active",
      role: "Admin",
      age: 27,
      imgUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    {
      name: "Cody Fisher",
      email: "cody.fisher@example.com",
      title: "Product Directives Officer",
      department: "Intranet",
      status: "Inactive",
      role: "Owner",
      age: 43,
      imgUrl:
        "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    {
      name: "Esther Howard",
      email: "esther.howard@example.com",
      title: "Forward Response Developer",
      department: "Directives",
      status: "Active",
      role: "Member",
      age: 32,
      imgUrl:
        "https://images.unsplash.com/photo-1520813792240-56fc4a3765a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    {
      name: "Jenny Wilson",
      email: "jenny.wilson@example.com",
      title: "Central Security Manager",
      department: "Program",
      status: "Offline",
      role: "Member",
      age: 29,
      imgUrl:
        "https://images.unsplash.com/photo-1498551172505-8ee7ad69f235?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    {
      name: "Kristin Watson",
      email: "kristin.watson@example.com",
      title: "Lean Implementation Liaison",
      department: "Mobility",
      status: "Inactive",
      role: "Admin",
      age: 36,
      imgUrl:
        "https://images.unsplash.com/photo-1532417344469-368f9ae6d187?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    {
      name: "Cameron Williamson",
      email: "cameron.williamson@example.com",
      title: "Internal Applications Engineer",
      department: "Security",
      status: "Active",
      role: "Member",
      age: 24,
      imgUrl:
        "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
  ];
  return [...data, ...data, ...data];
};

export default function AdminAppointments() {
  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Full Name",
          accessor: "userInfo.fullName",
          size: 100,
          Cell: ({ value }: { value: string }) => {
            return (
              <div className="flex items-center space-x-2 w-64">
                <div className="w-10 h-10 rounded-full ">
                  <img
                    src={`https://ui-avatars.com/api/?name=${value}&background=random&rounded=true&size=128`}
                    alt={value}
                    className="w-full h-full object-contain rounded-full"
                  />
                </div>
                <div
                  className="truncate w-full"
                  data-tooltip-id="name"
                  data-tooltip-content={value.length > 30 ? value : ""}
                >
                  {value}
                </div>
                {value.length > 30 && <Tooltip id="name" />}
              </div>
            );
          },
        },
        {
          Header: "Email",
          accessor: "userInfo.email",
          Cell: ({ value }: { value: string }) => {
            return (
              <div>
                <a
                  data-tooltip-id="email"
                  data-tooltip-content={value.length > 30 ? value : ""}
                  href={`mailto:${value}`}
                  className="text-indigo-600 hover:text-indigo-900 w-48 block truncate"
                >
                  {value}
                </a>
                {value.length > 30 && <Tooltip id="email" />}
              </div>
            );
          },
        },
        {
          Header: "Phone Number",
          accessor: "userInfo.phoneNumber",
          Cell: ({ value }: { value: string }) => {
            return (
              <a
                href={`tel:${value}`}
                className="text-indigo-600 hover:text-indigo-900 w-36 block truncate"
              >
                {value}
              </a>
            );
          },
        },
        {
          Header: "Membership ID",
          accessor: "userInfo.membershipId",
          Cell: ({ value }: { value: string }) => {
            // display full text tooltip
            return (
              <div className="flex items-start space-x-2 w-36">
                <div
                  className="truncate w-full"
                  data-tooltip-id="membership-id"
                  data-tooltip-content={value.length > 30 ? value : ""}
                >
                  {value}
                </div>
                {value.length > 30 && <Tooltip id="membership-id" />}
              </div>
            );
          },
        },
        {
          Header: "National ID",
          accessor: "userInfo.nationalId",
        },
        {
          Header: "# of members",
          accessor: "userInfo.numberOfMembers",
        },
        {
          Header: "Date",
          accessor: "date",
          Cell: ({ value }: { value: string }) => {
            // return new Date(value).toLocaleDateString("en-GB");
            return moment(value)
              .tz(process.env.REACT_APP_TZ!)
              .format("DD/MM/YYYY");
          },
        },
        {
          Header: "Time",
          id: "time",
          Cell: ({ renderedCellValue, row }: any) => {
            // const time = row.original.time;
            // const duration = row.original.duration;
            // const fromHour = Math.floor(time / 60);
            // const fromMinute = time % 60;
            // const fromampm = fromHour >= 12 ? "PM" : "AM";
            // const fromHour12 = fromHour % 12 || 12;
            // const fromMinuteStr = fromMinute < 10 ? `0${fromMinute}` : fromMinute;
            // const fromTime = `${fromHour12}:${fromMinuteStr} ${fromampm}`;

            // // get timezone offset in minutes
            // const toOffset = time + duration;
            // const toHour = Math.floor(toOffset / 60);
            // const toMinute = toOffset % 60;
            // const toampm = toHour >= 12 ? "PM" : "AM";
            // const toHour12 = toHour % 12 || 12;
            // const toMinuteStr = toMinute < 10 ? `0${toMinute}` : toMinute;
            // const toTime = `${toHour12}:${toMinuteStr} ${toampm}`;
            const [fromTime, toTime] = convertTimeToString(
              row.original.time,
              row.original.duration
            );
            return `${fromTime} - ${toTime}`;
          },
        },

        {
          Header: "Status",
          accessor: "status",
          Cell: StatusPill,
        },
        {
          Header: "# of attachments",
          Cell: ({ row }: any) => {
            return (
              <div className="text-center">{row.original.documents.length}</div>
            );
          },
          width: 50,
          maxWidth: 50,
          disableSortBy: true,
          disableResizing: true,
          disableFilters: true,
        },
        {
          Header: "",
          id: "actions",
          Cell: ({ row }: any) => {
            const appointment = row.original;
            return (
              <div className="flex space-x-2 rtl:space-x-reverse">
                <button
                  // to={`/admin/appointments/${appointment.id}`}
                  className="text-indigo-600 hover:text-indigo-900"
                  onClick={() => {
                    setVisibleRow(appointment);
                    setIsComponentVisible(true);
                  }}
                >
                  <PencilSquareIcon className="w-5 h-5" />
                </button>
              </div>
            );
          },
        },
        // {
        //   Header: "Age",
        //   accessor: "age",
        // },
        // {
        //   Header: "Role",
        //   accessor: "role",
        //   Filter: SelectColumnFilter, // new
        //   filter: "includes",
        // },
      ] as Column<IAppointmentPopulated>[],
    []
  );

  // const [page, setPage] = React.useState(1);
  // const [limit, setLimit] = React.useState(10);
  // const [q, setQ] = React.useState("");
  const [query, setQuery] = React.useState<{
    page: number;
    limit: number;
    q?: string | undefined;
    fromDate?: string | undefined;
    toDate?: string | undefined;
    status: "all" | "pending" | "completed" | "missed";
  }>({
    page: 1,
    limit: 10,
    status: "all",
  });

  // console.log("fromDate", query.fromDate, "toDate", query.toDate);

  console.log("query", query);

  const {
    data: appointments,
    isLoading,
    error,
  } = useGetAllAppointmentQuery({
    appointmentDefinitionId: process.env.REACT_APP_APPOINTMENT_ID,
    page: query.page,
    limit: query.limit,
    q: query.q,
    fromDate: query.fromDate,
    toDate: query.toDate,
    status: query.status === "all" ? undefined : query.status,
  });

  const [
    getAppointment,
    { data: exportAppointment, isLoading: isAppointmentLoading },
  ] = useLazyGetAllAppointmentQuery();

  const [updateAppointmentStatus] = useUpdateAppointmentStatusMutation();

  const data = React.useMemo(() => appointments, [appointments]);

  const [visibleRow, setVisibleRow] =
    React.useState<IAppointmentPopulated | null>(null);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false, (isVisiable) => {
      if (!isVisiable) {
        setVisibleRow(null);
      }
    });

  const handleUpdateStatus = async (
    status: "pending" | "completed" | "missed"
  ) => {
    if (visibleRow) {
      await updateAppointmentStatus({
        appointmentId: visibleRow.id,
        status,
      });
      setIsComponentVisible(false);
      setVisibleRow(null);
    }
  };

  const [isDownloading, setIsDownloading] = React.useState(false);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // console.log("visibleRow", visibleRow);

  async function downloadAttachments(
    documents: {
      fileUrl: string;
      title: string;
    }[]
  ) {
    try {
      if (!visibleRow) return;
      if (documents.length === 0) return;
      // download all files and zip them together
      const zip = new JSZip();
      const folder = zip.folder("attachments");
      const urlDataList: Blob[] = [];

      const urlDataResList = await Promise.all(
        documents.map(async (doc) => {
          return axios(`${doc.fileUrl}?not-from-cache-please`, {
            method: "GET",
            responseType: "blob",

            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
          });
        })
      );
      for (let i = 0; i < urlDataResList.length; i++) {
        // console.log("urlDataResList[i].data", urlDataResList[i].data);
        urlDataList.push(urlDataResList[i].data);
      }
      Promise.all(urlDataList).then((values) => {
        documents.forEach((doc, index) => {
          // console.log(`values ${values[index]}`);
          // filename is between %2F and ?
          let filename = doc.fileUrl.match(/%2F(.*?)\?/)?.[1];
          filename = !filename ? doc.fileUrl.split("/").pop() : filename;
          const blob = values[index];
          if (filename) {
            const ext = filename.split(".").pop();
            console.log(
              "filename",
              `${doc.title}_${new Date().getTime()}${index}.${ext}`
            );

            folder?.file(
              `${doc.title}_${new Date().getTime()}${index}.${ext}`,
              blob
            );
          }
        });
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(
            content,
            `${visibleRow.userInfo.fullName}-${visibleRow.date}-${visibleRow.time}.zip`
          );
        });
      });
    } catch (error) {
      // console.log("error", error);
    }
  }

  function readAllBytesAsUInt8Array(path: string) {
    var req = new XMLHttpRequest();
    req.open("GET", path, false);
    req.overrideMimeType("text/plain; charset=binary-data");
    req.send(null);
    if (req.status !== 200) {
      // console.log("error");
      return null;
    }
    var text = req.responseText;
    var encoder = new TextEncoder();
    var resultArray = encoder.encode(text);
    return resultArray.buffer;
  }

  async function onExport() {
    console.log("onExport");
    if (!data) return;
    if (data.results.length === 0) return;

    const total = data.totalResults;
    const dataToExport = await getAppointment({
      appointmentDefinitionId: process.env.REACT_APP_APPOINTMENT_ID,
      page: 1,
      limit: total,
      q: query.q,
      fromDate: query.fromDate,
      toDate: query.toDate,
      status: query.status === "all" ? undefined : query.status,
    }).unwrap();
    try {
      // download excel file
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const workbook = XLSX.utils.book_new(); // Create a new blank workbook
      const excelData = dataToExport!.results.map((row) => {
        const [fromTime, toTime] = convertTimeToString(row.time, row.duration);
        return {
          fullName: row.userInfo.fullName,
          email: row.userInfo.email,
          phoneNumber: row.userInfo.phoneNumber,
          membershipId: row.userInfo.membershipId,
          nationalId: row.userInfo.nationalId,
          numberOfMembers: row.userInfo.numberOfMembers,
          date: moment(row.date)
            .tz(process.env.REACT_APP_TZ!)
            .format("DD/MM/YYYY"),
          time: `${fromTime} - ${toTime}`,
          status: row.status,
          numberOfAttachments: row.documents.length,
          ...row.documents
            .map((doc, index) => {
              return {
                [`attachment${index + 1}_name`]: doc.title,
                [`attachment${index + 1}_url`]: doc.fileUrl,
              };
            })
            .reduce((acc, cur) => {
              return { ...acc, ...cur };
            }, {}),
        };
      });

      console.log("excelData", excelData);

      const worksheet = XLSX.utils.json_to_sheet(excelData); // Convert the data
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1"); // Add the worksheet to the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      }); // Write the workbook to a buffer
      const exportedData = new Blob([excelBuffer], { type: fileType }); // Return the buffer and blob
      saveAs(exportedData, "Appointment-" + Date.now() + fileExtension); // Create a link from the `href` attribute and download the file
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div className="flex flex-col w-full justify-center items-center space-y-6">
      {isComponentVisible && (
        <>
          <div className="fixed inset-0 z-10 bg-black bg-opacity-50"></div>
          <div className="fixed inset-0 z-10 flex justify-center  md:inset-0 h-[calc(100%-4rem)] max-h-full">
            <div
              ref={ref}
              className="bg-white w-1/2 rounded-lg p-4 overflow-x-hidden overflow-y-auto shadow-xl"
            >
              <div className="bg-white flex flex-col justify-center space-y-4 ">
                <div className="relative w-full">
                  <button
                    className="w-6 h-6 bg-gray-100 rounded-full shadow-slate-900 shadow-sm absolute top-1 right-1 z-50"
                    onClick={() => {
                      setIsComponentVisible(false);
                      setVisibleRow(null);
                    }}
                  >
                    <svg
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <AppointmentView
                  // location={visibleRow!.locationId}
                  // service={visibleRow!.serviceId}
                  date={new Date(visibleRow!.date)}
                  time={convertTimeToString(
                    visibleRow!.time,
                    visibleRow!.duration
                  )}
                  userForm={{
                    ...visibleRow!.userInfo,
                    id: "",
                  }}
                  documents={visibleRow!.documents}
                />
                {visibleRow!.documents.length > 0 && (
                  <div className="px-8 py-4 w-full flex justify-between items-center space-x-4 rtl:space-x-reverse">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                      onClick={async () => {
                        // setIsComponentVisible(false);
                        setIsDownloading(true);
                        await downloadAttachments(visibleRow!.documents);
                        setIsDownloading(false);
                      }}
                      disabled={isDownloading}
                    >
                      <div className="flex items-center space-x-2">
                        {isDownloading && (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            />
                          </svg>
                        )}
                        Download Attachments
                      </div>
                    </button>
                  </div>
                )}
                <div className="px-8 py-4 w-full flex justify-between items-center space-x-4 rtl:space-x-reverse">
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded-lg"
                    onClick={() => {
                      // setIsComponentVisible(false);
                      handleUpdateStatus("missed");
                    }}
                  >
                    Missed
                  </button>
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded-lg"
                    onClick={() => {
                      // setIsComponentVisible(false);
                      handleUpdateStatus("completed");
                    }}
                  >
                    Complete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Table
        columns={columns}
        data={data}
        onQueryChanged={(
          page: number,
          limit: number,
          q: string,
          { from, to }: { from: string; to: string },
          status: "all" | "pending" | "completed" | "missed"
        ) => {
          // console.log("onQueryChanged", page, limit, q, from, to);
          setQuery({ page, limit, q, fromDate: from, toDate: to, status });
        }}
        handleOnExport={onExport}
      />
    </div>
  );
}

function convertTimeToString(time: number, duration: number) {
  const fromHour = Math.floor(time / 60);
  const fromMinute = time % 60;
  const fromampm = fromHour >= 12 ? "PM" : "AM";
  const fromHour12 = fromHour % 12 || 12;
  const fromMinuteStr = fromMinute < 10 ? `0${fromMinute}` : fromMinute;
  const fromTime = `${fromHour12}:${fromMinuteStr} ${fromampm}`;

  // get timezone offset in minutes
  const toOffset = time + duration;
  const toHour = Math.floor(toOffset / 60);
  const toMinute = toOffset % 60;
  const toampm = toHour >= 12 ? "PM" : "AM";
  const toHour12 = toHour % 12 || 12;
  const toMinuteStr = toMinute < 10 ? `0${toMinute}` : toMinute;
  const toTime = `${toHour12}:${toMinuteStr} ${toampm}`;
  return [fromTime, toTime];
}
