import React from "react";
import { Link } from "react-router-dom";

export default function AdminHome() {
  return (
    <div className="flex flex-col w-full h-screen justify-center items-center space-y-6">
      <div className="text-4xl font-semibold">
        Welcome to TMG Clubs Appointments
        <br />
        Admin panel
      </div>
      <Link
        rel="noopener noreferrer"
        to="/admin/dash/appointments"
        className="px-8 py-3 font-semibold rounded-md  bg-blue-500 text-white"
      >
        Back to appointments
      </Link>
    </div>
  );
}
